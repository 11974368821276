import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  required,
  email,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import { parse } from 'query-string';
import { CreateToolbar } from "../../lib/components";

const SmsForwarderSettingCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { line_id: lineId, did_id: didId, number, email: currentEmail } = parse(props.location.search);

  const transform = data => {
    return {
      ...data,
      line_id: lineId,
      did_id: didId,
    }
  }

  const onFailure = (error) => {
    notify(`Could not create sms_forward_setting: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`sms_forward_setting created`);
    redirect(`/lines/${lineId}/show/1`);
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Manage SMS Forwarder on ${number}`} {...props}>
      <SimpleForm
        toolbar={<CreateToolbar />}
        initialValues={{ email_recipient_address: currentEmail, email_sender_address: process.env.REACT_APP_CREATE_SMS_FORWARDER_SETTING_DEFAULT_EMAIL, email_sender_name: process.env.REACT_APP_CREATE_SMS_FORWARDER_SETTING_DEFAULT_NAME }}
      >
        <TextInput label="Email Recipident Address" source="email_recipient_address" type="email" validate={[email()]} />
        <TextInput label="Email Sender Address" source="email_sender_address" type="email" validate={[email()]} />
        <TextInput label="Email Sender Name" source="email_sender_name" />
        {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
          <TextInput label="Telegram Chat Id" source="telegram_chat_id" />
        )}
      </SimpleForm>
    </Create>
  )
};

export default SmsForwarderSettingCreate;