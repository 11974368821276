import * as React from "react";
import { Admin, Resource, ListGuesser, ShowGuesser, EditGuesser } from 'react-admin';
import { Route } from "react-router";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { theme } from "./theme";

import alfredDataProvider from './lib/dataProvider';
import authProvider from './lib/authProvider';
import lines from './resources/lines';
import bulkRequests from './resources/bulk_requests'
import plans from './resources/plans';
import sims from './resources/sims';
import suspensions from './resources/suspensions';
import dids from './resources/dids';
import clids from './resources/clids';
import voicemails from './resources/voicemails';
import webhookEndpoints from './resources/webhook_endpoints';
import events from './resources/events';
import ipAddresses from './resources/ip_addresses';
import didRegistrations from './resources/did_registrations';
import otaRequests from './resources/ota_requests';
import showMeWhitePawAuthentications from './resources/show_me_white_paw_authentications';
import lineHomeRoamingOperators from './resources/line_home_roaming_operators';
import israeliPortabilityTools from './resources/israeli_portability_tools';
import tools from './resources/tools';
import smsForwarderSettings from './resources/sms_forwarder_settings';
import lineDidTenantSmppRoutes from './resources/line_did_tenant_smpp_routes';
import lineDidTenantVoiceRoutes from './resources/line_did_tenant_voice_routes';
import lineDidRingbackToneRoutes from './resources/line_did_ringback_tones';


const messages = {
  'en': englishMessages,
};
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'en', { allowMissing: true });

const customRoutes =
  [
    <Route
      key="check-availability"
      path="/check-availability"
      component={israeliPortabilityTools.checkAvailability}
    />,
    <Route
      key="find-operator"
      path="/find-operator"
      component={israeliPortabilityTools.findOperator}
    />
  ];

const App = () => (
  <Admin dataProvider={alfredDataProvider} customRoutes={customRoutes} theme={theme} authProvider={authProvider} i18nProvider={i18nProvider} disableTelemetry>
    <Resource name="lines" {...lines} />
    <Resource name="bulk_requests" {...bulkRequests} />
    <Resource name="plans" {...plans} />
    <Resource name="topups" />
    <Resource name="number_authentications" />
    <Resource name="clids" {...clids} />
    <Resource name="voicemails" {...voicemails} />
    <Resource name="sims" {...sims} />
    <Resource name="dids" {...dids} />
    <Resource name="did_registrations" {...didRegistrations} />
    <Resource name="ip_addresses" {...ipAddresses} />
    <Resource name="suspensions" {...suspensions} />
    <Resource name="audit/events" />
    <Resource name="webhook_endpoints" {...webhookEndpoints} />
    <Resource name="business_manager_webhook_conversations" />
    <Resource name="events" {...events} />
    <Resource name="tenants" />
    <Resource name="ota_requests" {...otaRequests} />
    <Resource name="show_me_white_paw_authentications" {...showMeWhitePawAuthentications} />
    <Resource name="line_home_roaming_operators" {...lineHomeRoamingOperators} />
    <Resource name="sim_manager_opls" />
    <Resource name="tools" {...tools} />
    <Resource name="sms_forwarder_settings" {...smsForwarderSettings} />
    <Resource name="tenant_smpp_routes" />
    <Resource name="tenant_voice_routes" />
    <Resource name="line_did_tenant_smpp_routes" {...lineDidTenantSmppRoutes} />
    <Resource name="line_did_tenant_voice_routes" {...lineDidTenantVoiceRoutes} />
    <Resource name="line_did_ringback_tones" {...lineDidRingbackToneRoutes} />
  </Admin>
);
export default App;