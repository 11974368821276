import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Button, FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from 'date-fns';

const styles = {
  button: {
    marginTop: '1em',
    width: '100%'
  }
};

const TriggerFilter = () => {
  const trigger = [
    "port_in",
    "port_out",
    "return"
  ];

  return (
    <FilterList label="Trigger">
      {trigger.map((s) => (
        <FilterListItem
          label={s}
          value={{ trigger: s }}
          key={s}
        />
      ))}
    </FilterList>
  )
};

const CreatedFilter = () => (
  <FilterList label="Created">
    <FilterListItem
      label="Today"
      value={{
        from_date: endOfYesterday().toISOString(),
        to_date: undefined,
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        from_date: startOfWeek(new Date()).toISOString(),
        to_date: undefined,
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        from_date: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        to_date: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        from_date: startOfMonth(new Date()).toISOString(),
        to_date: undefined,
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        from_date: subMonths(startOfMonth(new Date()), 1).toISOString(),
        to_date: startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Earlier"
      value={{
        from_date: undefined,
        to_date: subMonths(startOfMonth(new Date()), 1).toISOString(),
      }}
    />
  </FilterList>
);

const FilterSidebar = ({ setFilters, classes }) => (
  <Card style={{ marginLeft: '0.5em' }}>
    <CardContent>
      <Typography variant="h6">Filters:</Typography>
      <div><FilterLiveSearch label="Search" source="q" alwaysOn /></div>
      <TriggerFilter />
      <CreatedFilter />
      <Button className={classes.button} onClick={() => setFilters({})} label="Clear Filters" width="100%" />
    </CardContent>
  </Card>
);

export default withStyles(styles)(FilterSidebar);