import moment from 'moment';

class Debug {
  static activated() {
    let debug = process.env.REACT_APP_DEBUG || "0";
    return (debug === '1');
  }
  static log(obj) {
    if(Debug.activated()) {
      console.log(`${moment().format()} DEBUG:`);
      console.log(obj);
    }
  }
}

export default Debug;
