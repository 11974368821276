import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  Button,
  useNotify,
  useRedirect,
  useDataProvider
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { omit } from 'lodash';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const DuplicateBulkRequestButton = ({ record, classes }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const duplicateBulkRequest = () => {
    let payload = omit(record, ['line_id']);
    return dataProvider
      .create("bulk_requests", { data: payload })
      .then(result => {
        notify(`BulkRequest "${result.data.id}" created`);
        redirect(`/bulk_requests/${result.data.id}/show`);
      })
      .catch(error => {
        notify(`Could not create BulkRequest: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
      });
  }

  return <Button className={classes.button} label="Duplicate" onClick={duplicateBulkRequest}><FileCopyIcon /></Button>;
};

export default withStyles(styles)(DuplicateBulkRequestButton);

