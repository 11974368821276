import React, { useCallback, useMemo, useState } from 'react';
import { TextInput, BooleanInput, SimpleForm, useDataProvider, useNotify, useRefresh, required, SaveContextProvider } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';

const FindOperator = ({ staticContext, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState();
  const refresh = useRefresh();
  const [result, setResult] = useState("");

  const handleSave = useCallback((values) => {
    setSaving(true);
    let internalOnly = values.internal_only ? "true" : "false";
    dataProvider.create("find_operator", { data: { number: values.number.replace(/^0/, "+972"), internal_only: internalOnly } })
      .then(res => {
        setResult(`Operator: ${res.data.operator}`)
      })
      .catch(error => {
        setResult("An error occured")
      })
  }, [dataProvider, notify, refresh]);

  const saveContext = useMemo(() => ({
    save: handleSave,
    saving
  }), [saving, handleSave]);

  return (
    <SaveContextProvider value={saveContext}>
      <Typography variant="h6" style={{ marginLeft: "1em" }}>Find Number Operator</Typography>
      <SimpleForm save={handleSave} record={{}}>
        <TextInput source="number" validate={required()} />
        <BooleanInput label="Internal Only" source="internal_only" />
      </SimpleForm>
      {result !== "" && (<div style={{ background: "yellow", color: "red", padding: "1em", textAlign: "center", fontWeight: "bold" }}>{result}</div>)}
    </SaveContextProvider>
  );
};

export default FindOperator;