import React from "react";
import get from 'lodash/get';
import mod10CheckDigit from 'mod10-check-digit';

const ImeiField = ({ source, record = {} }) => {
  const imei = get(record, source);
  const imeiForSite = `${imei.substr(0, 14)}${mod10CheckDigit(imei.substr(0, 14))}`

  return <React.Fragment><a href={`https://www.imei.info/?imei=${imeiForSite}`} target="_blank">{imei}</a></React.Fragment>
}

export default ImeiField;