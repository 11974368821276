import React from "react";
import { parse } from 'query-string';
import BulkRequestCreateTypeCreate from './BulkRequestCreateTypeCreate';
import BulkRequestCreateTypeAdd from './BulkRequestCreateTypeAdd';

const BulkRequestCreate = props => {

  const { type } = parse(props.location.search)

  if (type === "add") {
    return <BulkRequestCreateTypeAdd {...props} />;
  }

  return <BulkRequestCreateTypeCreate {...props} />;
}

export default BulkRequestCreate;