import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  SelectInput,
  required,
} from 'react-admin';
import { parse } from 'query-string';
import { CreateToolbar } from "../../lib/components";

const ClidCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { line_id: lineId, caller_id: callerId } = parse(props.location.search);

  const transform = data => {
    return {
      line_id: lineId,
      caller_id: callerId,
      ...data
    }
  }

  const onFailure = (error) => {
    notify(`Could not create clid: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`Clid created`);
    redirect(`/lines/${lineId}/show/1`);
  };
  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Add Clid for ${callerId}`}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <SelectInput fullWidth source="destination_group_name" choices={[
          { id: 'EU', name: 'EU' },
          { id: 'IL', name: 'IL' },
          { id: 'INT', name: 'INT' },
          { id: 'US', name: 'US' },
          { id: 'IL801', name: 'IL801' },
          { id: 'EU801', name: 'EU801' },
          { id: 'US801', name: 'US801' }
        ]} validate={required()} />
        <SelectInput fullWidth source="service" choices={[
          { id: 'voice', name: 'voice' },
          { id: 'sms', name: 'sms' }
        ]} validate={required()} />
      </SimpleForm>
    </Create>
  )
}

export default ClidCreate;