import * as React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  required,
  email,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import { parse } from 'query-string';
import { FilteredSelectInput, CreateToolbar } from "../../lib/components";

const LineDidTenantSmppRouteCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { line_id: lineId, did_id: didId, number, email: currentEmail } = parse(props.location.search);

  const transform = data => {
    return {
      ...data,
      line_id: lineId,
      did_id: didId,
    }
  }

  const onFailure = (error) => {
    notify(`Could not create line_did_tenant_smpp_route: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`line_did_tenant_smpp_route created`);
    redirect(`/lines/${lineId}/show/1`);
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Manage Smpp Route on ${number}`} {...props}>
      <SimpleForm
        toolbar={<CreateToolbar />}
      >
        <ReferenceInput
          fullWidth
          source="tenant_smpp_route_id"
          reference="tenant_smpp_routes"
          allowEmpty
          validate={required()}
          filter={process.env.REACT_APP_TENANT_ID_SHORTCODE !== "" ? ({ tenant_id: `${process.env.REACT_APP_TENANT_ID_SHORTCODE}` }) : ({})}
          sort={{}}
          perPage={10000}
        >
          <FilteredSelectInput optionText="smpp_route" optionValue="id" filter={(choices) => {
            return choices;
          }} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
};

export default LineDidTenantSmppRouteCreate;