import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required
} from 'react-admin';
import { parse } from 'query-string';
import { CreateToolbar, FreeIpListInput } from '../../lib/components';

const ShowMeWhitePawAuthenticationCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const transform = data => {
    return data
  }

  const onFailure = (error) => {
    notify(`Could not create authentication: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`Authentication created`);
    redirect(`/show_me_white_paw_authentications`);
  };

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Create Authentication`}>
      <SimpleForm toolbar={<CreateToolbar />}
        initialValues={{ "authentication_type": "ivr", "locale": process.env.REACT_APP_CREATE_LINE_DEFAULT_LANGUAGE, "authentication_context": "port_in" }}
      >
        <TextInput fullWidth source="number" label="Number" validate={required()} />
        <SelectInput fullWidth source="authentication_type" choices={[
          { id: 'sms_code', name: 'SMS' },
          { id: 'ivr', name: 'Call' }
        ]} validate={required()} />
        <SelectInput fullWidth source="locale" choices={[
          { id: 'en_US', name: 'English' },
          { id: 'fr_FR', name: 'French' },
          { id: 'he_IL', name: 'Hebrew' }
        ]} validate={required()} />
        <SelectInput fullWidth source="authentication_context" choices={[
          { id: 'port_in', name: 'port_in' }
        ]} validate={required()} />
      </SimpleForm>
    </Create>
  )
}

export default ShowMeWhitePawAuthenticationCreate;