import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  required,
  SelectInput
} from 'react-admin';
import { parse } from 'query-string';
import { CreateToolbar } from '../../lib/components';

const SuspensionCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { line_id: lineId } = parse(props.location.search);

  const transform = data => {
    return {
      line_id: lineId,
      type: data.type
    }
  }

  const onFailure = (error) => {
    notify(`Could not create suspension: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`Suspension created`);
    redirect(`/lines/${lineId}/show/4`);
  };
  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Add suspension on line ${lineId}`}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <SelectInput
          fullWidth
          source="type"
          validate={required()}
          choices={[
            { id: 'block', name: 'Block' },
            { id: 'freeze', name: 'Freeze' },
          ]} />
      </SimpleForm>
    </Create>
  )
}

export default SuspensionCreate;