import React from 'react';
import { Link } from 'react-router-dom';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import find from 'lodash/find';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const ReplacePlanButton = ({ classes, record }) => {
  let planId = "";
  if (record) {
    let plan = find(record.plans, (plan => plan.end_at === null && plan.plan.is_main));
    if (plan) {
      planId = plan.id;
    }
  }
  return (
    <span>{record && (
      <Button

        className={classes.button}
        component={Link}
        to={`/plans/create?line_id=${record.id}&is_main=1&plan_id=${planId}`}
        label="Replace plan"
        title="Replace the main plan"
      >
        <PhoneIphoneIcon />
      </Button>
    )}</span>
  )
};

export default withStyles(styles)(ReplacePlanButton);
