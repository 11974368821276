
import React from 'react';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';
import BackButton from './BackButton';

const CreateToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
    <BackButton />
    {props.withDelete && (<DeleteButton undoable={false} style={{ position: 'absolute', right: 0 }} />)}
  </Toolbar>
);

export default CreateToolbar;