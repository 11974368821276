import React from 'react';
import { cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ExportButton,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  Filter,
  TextInput,
  Button,
  ReferenceField
} from 'react-admin';

const IpAddressFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);


const IpAddressListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    setFilters,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <Button onClick={() => setFilters({})} label="Clear Filters" />
      <ExportButton

        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        style={{ marginLeft: '0.5em' }}
      />
    </TopToolbar>
  );
};

const IpAddressList = props => (
  <List
    {...props}
    filters={<IpAddressFilter />}
    bulkActionButtons={false}
    actions={<IpAddressListActions />}
    sort={{ field: 'ip', order: 'ASC' }}
  >
    <Datagrid>
      {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
        <ReferenceField label="Tenant" source="tenant_id" reference="tenants" link={false}>
          <TextField source="name" />
        </ReferenceField>
      )}
      <TextField source="ip" />
    </Datagrid>
  </List>
);

export default IpAddressList;