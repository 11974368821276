import React from 'react';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import {
  useNotify,
  useDataProvider,
  useRefresh,
  Button
} from 'react-admin';
import { get } from 'lodash';

const styles = {
  button: {
    marginLeft: '0.5em'
  }
};

const CreateAuthenticationButton = ({ classes, record, locale, authenticationType, successMessage, label }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const createAuthentication = () => {
    dataProvider
      .create('show_me_white_paw_authentications', { data: { number: record.number, authentication_context: "port_in", authentication_type: authenticationType, locale: locale } })
      .then(_response => {
        notify(successMessage)
        refresh()
      })
      .catch(error => {
        let errors = get(error.body, "errors");
        if (errors) {
          notify(`Could not create authentication: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        } else {
          notify(successMessage)
        }
        refresh()
      })
  }

  return <Button
    onClick={createAuthentication}

    className={classes.button}
    label={label}
    title={label}
  >
    <SendIcon />
  </Button>;
}


export default withStyles(styles)(CreateAuthenticationButton);