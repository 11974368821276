import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField
} from 'react-admin';


const WebhookEndpointList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: 'created_at', order: 'ASC' }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="url" />
      <BooleanField source="is_insecure_allowed" />
      <BooleanField source="is_enabled" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export default WebhookEndpointList;