import React, { useState, useEffect } from 'react';
import { AutocompleteInput, TextInput } from 'react-admin';

const FreeIpListInput = ({ label, name, tenantId, ...attrs }) => {
  const [hasError, setErrors] = useState(true);
  const [ips, setIps] = useState([]);

  let tenant = typeof tenantId !== 'undefined' ? tenantId : `${process.env.REACT_APP_TENANT_ID_UUID}`;

  useEffect(() => {
    async function fetchData() {
      if (ips.length === 0) {
        let apiKey = `${process.env.REACT_APP_API_KEY}` || localStorage.getItem('api_key');
        let params = {
          method: 'post',
          headers: {
            "x-api-key": `${apiKey}`
          },
          body: ""
        }
        const res = await fetch(`${process.env.REACT_APP_DID_LIST_API_URL}?module=ip_list&tenantId=${tenant}`, params);
        res
          .json()
          .then(res => {
            setErrors(false);
            setIps(res);
          })
          .catch(err => {
            console.log(err)
            setErrors(err)
          });
      }
    }

    fetchData();
  }, []);

  if (typeof ips !== "undefined" && ips.length > 0) {
    return (
      <AutocompleteInput choices={ips} optionText="ip" optionValue="ip" {...attrs} suggestionLimit={25} />
    )
  } else {
    return (<TextInput {...attrs} />)
  }

}

export default FreeIpListInput;