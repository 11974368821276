import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
} from 'react-admin';
import { CreateToolbar } from "../../lib/components";
import { parse } from 'query-string';


const Title = ({ line_id }) => {
  return <span>Add ported number on line #{line_id}</span>
}

const BulkRequestCreateTypeAdd = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const transform = data => {

    let dids = [{ number: data.portin_did_number }];
    let portInRequest = { number: data.portin_did_number, identity_number: data.identity_number, authentication_type: data.authentication_type };

    return {
      type: "add",
      dids: dids,
      port_in_request: portInRequest,
      line_id: data.line_id
    }
  }

  const onFailure = (error) => {
    notify(`Could not create BulkRequest: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`BulkRequest "${data.id}" created`);
    redirect(`/bulk_requests/${data.id}/show`);
  };

  const { line_id, language } = parse(props.location.search)

  return (
    <Create {...props} title={<Title line_id={line_id} />} onSuccess={onSuccess} onFailure={onFailure} transform={transform}>
      <SimpleForm toolbar={<CreateToolbar />} initialValues={{ "identity_number": process.env.REACT_APP_CREATE_LINE_DEFAULT_IDENTITY_NUMBER, "has_port_in_request": true, line_id: line_id, "authentication_type": "sms_code", language: language }}>
        <TextInput fullWidth source="line_id" label="Line ID" />
        <TextInput fullWidth source="identity_number" label="Teoudat Zeout" validate={required()} />
        <TextInput fullWidth source="portin_did_number" label="Ported number" validate={required()} />
        <SelectInput fullWidth source="authentication_type" choices={[
          { id: 'sms_code', name: 'SMS' },
          { id: 'ivr', name: 'Call' }
        ]} validate={required()} />
      </SimpleForm>
    </Create>
  )
}

export default BulkRequestCreateTypeAdd;