import React, { useState, useEffect } from 'react';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider
} from 'react-admin';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const DoActionButton = ({ number, is_open_to_port_out, label }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleClick = () => {
    dataProvider.update("dids", { id: number, data: { is_open_to_port_out: is_open_to_port_out } })
      .then(res => {
        refresh();
        notify(`done!`);
      })
  };

  return (<MenuItem onClick={handleClick}><ExitToAppIcon />{label}</MenuItem>)
}

const PortableNumberButton = ({ record }) => {
  const { number } = record;
  const [hasError, setErrors] = useState(true);
  const [status, setStatus] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const dataProvider = useDataProvider();

  const handleClick = (event) => {
    if (status === null) {
      dataProvider.getOne("dids", { id: number })
        .then(({ data }) => {
          setErrors(false);
          if (data.is_open_to_port_out) {
            setStatus('portable');
          } else {
            setStatus('not_portable');
          }
        })
        .catch(err => {
          console.log(err)
          setErrors(err)
        });
    }
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <Button label="Manage Portout " onClick={handleClick}><ExitToAppIcon /></Button>
      <Menu
        id="portout-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {status === 'portable' && (<DoActionButton is_open_to_port_out={false} number={number} label="Block Port Out" />)}
        {status === 'not_portable' && (<DoActionButton is_open_to_port_out={true} number={number} label="Allow Port Out" />)}
      </Menu>
    </span>
  );
}
export default PortableNumberButton;