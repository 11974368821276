class LineService {
  static format_balances(balances) {
    return balances.map(balance => {
      return LineService.format_balance(balance);
    })
  }

  static format_balance(balance) {
    switch (balance.type) {
      case '*data':
        balance.value = Math.round(balance.value / 1000 / 1000) + " MB";
        break;
      case '*voice':
        balance.value = Math.round(balance.value / 60 / 1000000000) + " min";
        break;
      default:
    }
    if (typeof balance.categories !== 'string') {
      balance.categories = balance.categories.join(", ");
    }
    return balance;
  }

  static get_formated_value(type, value) {
    switch (type) {
      case '*data':
        return Math.round(value / 1000 / 1000) + " MB";
      case '*voice':
        return Math.round(value / 60 / 1000000000) + " min";
      default:
        return value
    }
  }

  static get_formated_value_rounded(type, value) {
    switch (type) {
      case '*data':
        return Math.round(Math.ceil(value / 1000 / 1000 / 100) * 100) + " MB";
      case '*voice':
        return Math.round(Math.ceil(value / 60 / 1000000000 / 10) * 10) + " min";
      default:
        return value
    }
  }

  static getApnList() {
    let apnList = [];
    if (process.env.REACT_APP_TENANT_ID_UUID === "3f1d680e-3f12-416b-3f18-3f3f0d3f6c00") {
      apnList.push({ id: 'annatel', name: 'annatel' });
      apnList.push({ id: 'annatel.safe', name: 'annatel.safe' });
    }
    apnList.push({ id: 'internet', name: 'internet' });
    apnList.push({ id: 'internet.safe', name: 'internet.safe' });
    apnList.push({ id: 'netfree', name: 'netfree' });
    return apnList;
  }
}

export default LineService;