import React from "react";
import get from 'lodash/get';
import { Link } from 'react-router-dom';

const IccIdField = ({ source, record = {} }) => {
  const iccId = get(record, source);

  return <Link to={`/sims/${iccId}/show`}>{iccId}</Link>
}

export default IccIdField;