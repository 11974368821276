import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Link
} from 'react-admin';
import get from 'lodash/get';
import { JSONShow } from '../../lib/components';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const TacButton = ({ classes, record, source }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
    return false;
  };

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    return false;
  };

  const tac = get(record, source);

  if (tac === null) {
    return <React.Fragment></React.Fragment>
  }

  const modelName = get(tac, "model_name");

  return (
    <React.Fragment>
      <a href="#"
        onClick={handleClickOpen}
      >
        {modelName}
      </a>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">User Equipment Info</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <JSONShow record={tac} expandNode={true}></JSONShow>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus label="Close" />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styles)(TacButton);
