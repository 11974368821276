import React, { useState, useEffect } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {
  useDataProvider
} from 'react-admin';

const PortableNumberField = ({ record }) => {
  const { number } = record;
  const [hasError, setErrors] = useState(true);
  const [status, setStatus] = useState(null);
  const dataProvider = useDataProvider();

  useEffect(() => {
    async function fetchData() {
      if (status === null) {
        dataProvider.getOne("dids", { id: number })
          .then(({ data }) => {
            setErrors(false);
            if (data.is_open_to_port_out) {
              setStatus('portable');
            } else {
              setStatus('not_portable');
            }
          })
          .catch(err => {
            console.log(err)
            setErrors(err)
            setStatus('ported out');
          });
      }
    }

    fetchData();
  }, null);

  if (status === null) {
    return (
      <span key={`portablenumber${record.id}`}>Loading...
      </span>)
  } else {
    return (
      <span key={`portablenumber${record.id}`}>
        {status === 'portable' && (<CheckIcon />)}
        {status === 'not_portable' && (<CloseIcon />)}
        {status === 'ported out' && (<span>PO</span>)}
      </span>)
  }
}
PortableNumberField.defaultProps = { label: 'Is Portable' };

export default PortableNumberField;