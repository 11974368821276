import * as React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';

const HasPelephoneOpl = ({ record, fromLine }) => {
  //return (<React.Fragment></React.Fragment>);
  const sim = fromLine ? get(record, 'sim') : record;
  const opls = get(sim, 'opls', []);
  const opl = find(opls, (opl) => {
    return opl.mcc_mnc === '42503';
  })

  const confirmed = opl && sim.spn === "" && sim.last_refresh_at !== null;

  if (confirmed) {
    return (<span style={{ backgroundColor: '#2566CC', color: '#ffffff', fontSize: '11px', padding: '3px' }}>PL</span>)
  }


  return opl ? (<span style={{ backgroundColor: '#f0f0f0', color: '#000000', fontSize: '11px', padding: '3px' }}>PL</span>) : (<React.Fragment></React.Fragment>);

}

export default HasPelephoneOpl;