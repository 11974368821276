import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  useDataProvider
} from 'react-admin';
import JSONShow from '../../lib/components/JSONShow';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const GetEsimProfileButton = ({ classes, record }) => {
  const [open, setOpen] = React.useState(false);
  const [response, setResponse] = React.useState([]);
  const dataProvider = useDataProvider();

  if (typeof record === "undefined") {
    return (<span></span>);
  }

  const { icc_id, type } = record;

  const handleClickOpen = () => {
    return dataProvider
      .getByUrl(`operational/sim_manager/sims/${icc_id}/profile`)
      .then((data) => {
        let info = data || {};
        setResponse(info);
        setOpen(true);
      })
      .catch(error => {
        setResponse(error);
        setOpen(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (typeof type === 'undefined' || type !== "esim") {
    return (<span></span>)
  }

  return (
    <div>
      <Button
        className={classes.button}

        label="Profile"
        onClick={handleClickOpen}
      >
        <PermScanWifiIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Profile</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Table>
              <TableBody>
                <JSONShow record={response} />
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus label="Close" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(GetEsimProfileButton);
