import React, { Fragment } from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

const ExternalPortInRequestDetails = ({ record }) => {
  const externalPortInRequests = get(record, 'external_port_in_requests', []);
  return (
    <Fragment>
      {externalPortInRequests.map(externalPortInRequests => {
        const transactions = get(externalPortInRequests, 'detail.data.transactions', [])
        return (
          <Fragment>
            <Typography variant="h6" gutterBottom component="div">Request {externalPortInRequests.external_id}</Typography>
            <Table style={{ marginBottom: '1em' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Message Type</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Source</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Target</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Donor</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Last Transaction Time</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Ack Code</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Reject Reason Code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map(transaction => (
                  <Fragment>
                    <TableRow key={transaction.id}>
                      <TableCell>{transaction.message_type}</TableCell>
                      <TableCell>{transaction.source}</TableCell>
                      <TableCell>{transaction.target}</TableCell>
                      <TableCell>{transaction.donor}</TableCell>
                      <TableCell>{moment(transaction.last_transaction_time).format("DD/MM/YYYY, HH:mm:ss")}</TableCell>
                      <TableCell>{transaction.ack_code}</TableCell>
                      <TableCell>{transaction.reject_reason_code}</TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </Fragment>
        )
      })}
    </Fragment>
  );
}

export default ExternalPortInRequestDetails;