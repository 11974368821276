import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const AddPlanButton = ({ classes, record }) => (
  <span>{record && (
    <Button

      className={classes.button}
      component={Link}
      to={`/plans/create?line_id=${record.id}&is_main=0`}
      label="Add plan"
      title="Add a plan"
    >
      <AddIcon />
    </Button>
  )}</span>
);

export default withStyles(styles)(AddPlanButton);
