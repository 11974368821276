import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BulkRequestCreate from './BulkRequestCreate';
import BulkRequestList from './BulkRequestList';
import BulkRequestShow from './BulkRequestShow';

const index = {
  create: BulkRequestCreate,
  list: BulkRequestList,
  show: BulkRequestShow,
  icon: LibraryBooksIcon
};

export default index;
