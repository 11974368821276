import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
} from 'react-admin';

const CloseLineButton = ({ record, classes }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  // if (record.end_at !== null) {
  //   return <span />
  // }

  const close = () => {
    if (window.confirm("Click on OK will close all components in the line\nAre you sure?")) {
      return dataProvider
        .create("bulk_requests", { data: { line_id: record.id, type: "remove" } })
        .then(response => {
          refresh();
          notify(`Line will be closed in a minute`);
        })
        .catch(error => {
          notify(`Could not close the line: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        });
    }
    return false;
  }

  return <Button label="Close Line" title="Close the line and all components" onClick={close} style={{ color: 'red' }}><DeleteIcon /></Button>;
};

export default CloseLineButton;

