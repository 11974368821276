import React from 'react';
import LoopIcon from '@material-ui/icons/Loop';
import {
  Button,
  useNotify,
  useDataProvider
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const HardResetLineButton = ({ record, classes }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const hardResetLine = () => {
    return dataProvider
      .create(`operational/network_manager/lines/${record.id}/hard_reset`, {})
      .then(response => {
        notify(`Hard Reset Done!`, "info");
      })
      .catch(error => {
        notify(`Hard Reset Done!`, "info");
      });
  }

  return <Button className={classes.button} label="Hard Reset" title="Hard reset the line on the HLR" onClick={hardResetLine}><LoopIcon /></Button>;
};

export default withStyles(styles)(HardResetLineButton);

