import * as React from 'react';
import {
  Show,
  TextField,
  ArrayField,
  SingleFieldList,
  ReferenceManyField,
  Datagrid,
  BooleanField,
  DateField,
  Pagination
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import { TextArrayField, JSONShow } from '../../lib/components';

const WebhookConversations = (props) => {
  let record = props.record;
  return (
    <ReferenceManyField
      {...props}
      reference={`business_manager_webhook_conversations`}
      target="webhook_endpoint_id"
      sort={false}
      perPage={100}
      pagination={<Pagination />}
    >
      <Datagrid expand={<JSONShow />} rowClick="expand">
        <TextField source="webhook_notification.ref" />
        <DateField source="requested_at" showTime />
        <DateField source="responded_at" showTime />
        <TextField source="status" />
      </Datagrid>
    </ReferenceManyField>
  )
}

const WebhookEndpointShow = props => (
  <Show {...props}>
    <BoxedShowLayout className="BoxedShowLayout">
      <RaBox width="100%" style={{ borderBottom: "1px solid #f0f0f0", padding: "0 0 20px 0" }} mt="20px">
        <Typography variant="h6" style={{ marginBottom: '1em' }}>Infos</Typography>
        <RaBox display="flex" flexWrap="wrap">
          <RaBox flex="0 0 50%" display="flex">
            <TextField source="id" />
          </RaBox>
          <RaBox flex="0 0 50%" display="flex">
            <TextField source="tenant_id" />
          </RaBox>
          <RaBox flex="0 0 50%" display="flex">
            <TextField source="url" />
          </RaBox>
          <RaBox flex="0 0 50%" display="flex">
            <TextArrayField source="enabled_notification_patterns" linkType={false} />
          </RaBox>
          <RaBox flex="0 0 50%" display="flex">
            <BooleanField source="is_insecure_allowed" />
          </RaBox>
          <RaBox flex="0 0 50%" display="flex">
            <BooleanField source="is_enabled" />
          </RaBox>
          <RaBox flex="0 0 50%" display="flex">
            <DateField source="created_at" showTime />
          </RaBox>
        </RaBox>
      </RaBox>
      <RaBox width="100%" style={{ padding: "0 0 20px 0" }} mt="20px">
        <Typography variant="h6" style={{ marginBottom: '1em' }}>Webhook Conversations</Typography>
        <WebhookConversations />
      </RaBox>
    </BoxedShowLayout>
  </Show>
)

export default WebhookEndpointShow;
