import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  required,
  email,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import { parse } from 'query-string';
import { CreateToolbar } from "../../lib/components";

const VoicemailCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { line_id: lineId, did_id: didId, number, email: currentEmail } = parse(props.location.search);

  const getGreetingLanguage = (number) => {
    if (number.match(/^\+33/)) {
      return 'fr';
    }
    if (number.match(/^\+972/)) {
      return 'he';
    }
    return 'en';
  }

  const transform = data => {
    return {
      ...data,
      timezone: 'israel',
      line_id: lineId,
      did_id: didId,
    }
  }

  const onFailure = (error) => {
    notify(`Could not create voicemail: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`voicemail created`);
    redirect(`/lines/${lineId}/show/1`);
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Manage voicemail on ${number}`} {...props}>
      <SimpleForm
        toolbar={<CreateToolbar />}
        initialValues={{ email: currentEmail, fullname: currentEmail, password: '1234', language: process.env.REACT_APP_CREATE_VOICEMAIL_DEFAULT_LANGUAGE, greeting_language: getGreetingLanguage(number), are_recordings_saved_on_server: false, are_recordings_sent_to_email: true }}
      >
        <TextInput label="Email Address" source="email" type="email" validate={[required(), email()]} />
        <TextInput label="Full Name" source="fullname" validate={required()} />
        <TextInput label="Password" source="password" validate={required()} />
        <SelectInput label="Email Language" source="language" choices={[
          { id: 'fr', name: 'French' },
          { id: 'en', name: 'English' },
          { id: 'he', name: 'Hebrew' },
        ]} validate={required()} />
        <SelectInput label="Greeting Language" source="greeting_language" choices={[
          { id: 'fr', name: 'French' },
          { id: 'en', name: 'English' },
          { id: 'he', name: 'Hebrew' },
        ]} validate={required()} />
        <BooleanInput source="are_recordings_saved_on_server" label="Leave the messages on the server" fullWidth />
        <BooleanInput source="are_recordings_sent_to_email" label="Send the messages by email" fullWidth />
      </SimpleForm>
    </Create>
  )
};

export default VoicemailCreate;