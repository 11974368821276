import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import LineList from './LineList';
import LineShow from './LineShow';
import LineEdit from './LineEdit';

const index = {
  list: LineList,
  show: LineShow,
  edit: LineEdit,
  icon: PhoneAndroidIcon
};

export default index;
