import React from 'react';
import { Button } from 'react-admin';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    marginLeft: '1em',
    paddingTop: '7px',
    paddingBottom: '7px',
  }
};


const BackButton = ({ label, classes, ...rest }) => {
  label = label || "Go Back"

  const goBack = () => {
    window.history.back();
  }
  return <Button
    className={classes.button}
    onClick={goBack}
    label={label}

    color="inherit"
    {...rest}
  >
    <ArrowBack />
  </Button>;
}

export default withStyles(styles)(BackButton);