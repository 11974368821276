import React from 'react';
import { Link } from 'react-router-dom';
import SimCardIcon from '@material-ui/icons/SimCard';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import find from 'lodash/find';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const AddSimButton = ({ classes, record }) => {
  let isMain = "0";
  if (record) {
    let sim = find(record.sims, (sim => sim.end_at === null && sim.is_main));
    if (!sim) {
      isMain = "1";
    }
  }
  return (
    <span>{record && (
      <Button

        className={classes.button}
        component={Link}
        to={`/sims/create?line_id=${record.id}&is_main=${isMain}`}
        label="Add sim"
        title="Add a sim"
      >
        <SimCardIcon />
      </Button>
    )}</span>
  )
};

export default withStyles(styles)(AddSimButton);
