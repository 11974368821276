import React from 'react';
import { Link } from 'react-router-dom';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import find from 'lodash/find';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const ReplaceSimButton = ({ classes, record }) => {
  let simId = "";
  if (record) {
    let sim = find(record.sims, (sim => sim.end_at === null && sim.is_main));
    if (sim) {
      simId = sim.id;
    }
  }
  return (
    <span>{record && (
      <Button

        className={classes.button}
        component={Link}
        to={`/sims/create?line_id=${record.id}&is_main=1&sim_id=${simId}`}
        label="Replace sim"
        title="Replace the main sim"
      >
        <RestorePageIcon />
      </Button>
    )}</span>
  )
};

export default withStyles(styles)(ReplaceSimButton);
