import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Button,
  FilterLiveSearch,
  ReferenceField
} from 'react-admin';
import { LargePagination, JSONShow } from '../../lib/components';

const styles = {
  button: {
    marginTop: '1em',
    width: '100%'
  }
};

const FilterSidebar = ({ setFilters, classes }) => (
  <Card style={{ marginLeft: '0.5em' }}>
    <CardContent>
      <Typography variant="h6">Filters:</Typography>
      <div><FilterLiveSearch label="Search" source="q" alwaysOn /></div>
      <div><FilterLiveSearch source="resource_object" label="Resource Object" /></div>
      <div><FilterLiveSearch source="resource_id" label="Resource Id" /></div>
      <div><FilterLiveSearch source="type" label="Type" /></div>
      <Button className={classes.button} onClick={() => setFilters({})} label="Clear Filters" width="100%" />
    </CardContent>
  </Card>
);

const EventSidebar = withStyles(styles)(FilterSidebar);

const EventList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: 'sequence', order: 'DESC' }}
    perPage={100}
    pagination={<LargePagination />}
    aside={<EventSidebar />}
  >
    <Datagrid expand={<JSONShow />} rowClick="expand">
      {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
        <ReferenceField label="Tenant" source="tenant_id" reference="tenants" link={false}>
          <TextField source="name" />
        </ReferenceField>
      )}
      <TextField source="resource_object" />
      <TextField source="resource_id" />
      <TextField source="type" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);

export default EventList;