import React from 'react';
import { cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  TextInput,
  TopToolbar,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
  Button,
  ReferenceField
} from 'react-admin';
import StatusField from './StatusField';
import QuickFilter from '../../lib/components/QuickFilter';
import LineListSidebar from './LineListSidebar';

const LineFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <QuickFilter source="ongoing_at" label="Ongoing" />
  </Filter>
);

const LineListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    setFilters,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      {/* <Button onClick={() => setFilters({})} label="Clear Filters" /> */}
      <ExportButton

        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        style={{ marginLeft: '0.5em' }}
      />
    </TopToolbar>
  );
};

const CustomChipField = ({ record, source }) => {
  let style = {}
  if (record.end_at === null) {
    style = {
      border: '1px solid #203890',
      background: '#ffffff'
    }
  }
  return <ChipField record={record} source={source} style={style} />
}

const LineList = props => (
  <List
    {...props}
    // filters={<LineFilter />}
    filterDefaultValues={{ ongoing_at: 'now' }}
    bulkActionButtons={false}
    actions={<LineListActions />}
    sort={{ field: 'inserted_at', order: 'DESC' }}
    aside={<LineListSidebar />}
  >
    <Datagrid rowClick="show">
      <StatusField />
      <TextField source="id" />
      {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
        <ReferenceField label="Tenant" source="tenant_id" reference="tenants" link={false}>
          <TextField source="name" />
        </ReferenceField>
      )}
      <ArrayField source="dids" linkType={false}><SingleFieldList><CustomChipField source="number" /></SingleFieldList></ArrayField>
      <ArrayField source="sims" linkType={false}><SingleFieldList><CustomChipField source="icc_id" /></SingleFieldList></ArrayField>
      <ArrayField source="plans" linkType={false}><SingleFieldList><CustomChipField source="plan.name" /></SingleFieldList></ArrayField>
      <DateField source="inserted_at" showTime />
    </Datagrid>
  </List>
);

export default LineList;