import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  required,
} from 'react-admin';
import { parse } from 'query-string';
import { FreeDidListInput, CreateToolbar } from "../../lib/components";

const DidCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { line_id: lineId } = parse(props.location.search);

  const transform = data => {
    return {
      line_id: lineId,
      number: data.number
    }
  }

  const onFailure = (error) => {
    notify(`Could not create did: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`Did created`);
    redirect(`/lines/${lineId}/show/1`);
  };
  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Add Did on line ${lineId}`}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <FreeDidListInput
          fullWidth
          source="number"
          filter={(data) => {
            return data.filter((did) => true)
          }}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  )
}

export default DidCreate;