import React from 'react';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import {
  Button,
  useNotify,
  useDataProvider,
  useRefresh
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const AddTragofoneButton = ({ record, classes }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  if (typeof record === "undefined" || record.id === null) {
    return (<React.Fragment></React.Fragment>);
  }
  const lineId = record.id;

  const AddTragofone = () => {
    return dataProvider
      .create(`line_tragofone_accounts`, { data: { line_id: lineId, add_line_sip_trunk: "true" } })
      .then(response => {
        refresh();
        notify(`Adding Phone.app in process!`, "info");
      })
      .catch(error => {
        refresh();
        notify(`Adding Phone.app in process!`, "info");
      });
  }

  return (<Button className={classes.button} label="Add ph.app" title="Add a phone.app and sip account to the line" onClick={AddTragofone}><PhonelinkSetupIcon /></Button>);
};

export default withStyles(styles)(AddTragofoneButton);

