import React, { useState, useEffect } from 'react';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider
} from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const DoActionButton = ({ number, is_open_to_port_out, label }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleClick = () => {
    dataProvider.update("dids", { id: number, data: { is_open_to_port_out: is_open_to_port_out } })
      .then(res => {
        refresh();
        notify(`done!`);
      })
  };

  const color = is_open_to_port_out ? 'red' : 'green';

  return (<Button label="" style={{ color: color, fontSize: "1.5rem" }} onClick={handleClick}>{(is_open_to_port_out ? <CloseIcon /> : <CheckIcon />)}</Button>)
}

const PortableNumberButton = ({ record }) => {
  const { number } = record;
  const [hasError, setErrors] = useState(true);
  const [status, setStatus] = useState(null);
  const dataProvider = useDataProvider();

  useEffect(() => {
    async function fetchData() {
      if (status === null) {
        dataProvider.getOne("dids", { id: number })
          .then(({ data }) => {
            setErrors(false);
            if (data.is_open_to_port_out) {
              setStatus('portable');
            } else {
              setStatus('not_portable');
            }
          })
          .catch(err => {
            console.log(err)
            setErrors(err)
            setStatus('ported out');
          });
      }
    }

    fetchData();
  }, null);

  if (status === null) {
    return (
      <span key={`portablenumber${record.id}`}>Loading...
      </span>)
  } else {
    return (
      <span key={`portablenumber${record.id}`}>
        {status === 'portable' && (<DoActionButton is_open_to_port_out={false} number={number} label="Block Port Out" />)}
        {status === 'not_portable' && (<DoActionButton is_open_to_port_out={true} number={number} label="Allow Port Out" />)}
        {status === 'ported out' && (<span>PO</span>)}
      </span>)
  }
}

PortableNumberButton.defaultProps = { label: 'Portable?' };

export default PortableNumberButton;