import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import Debug from './debug/Debug';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import get from 'lodash/get';
import { parse } from 'query-string';


const alfredApiUrl = `${process.env.REACT_APP_ALFRED_API_URL}`;
const businessManagerApiUrl = `${process.env.REACT_APP_BUSINESS_MANAGER_API_URL}`;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  let apiKey = `${process.env.REACT_APP_API_KEY}` || localStorage.getItem('api_key');
  options.headers.set('x-api-key', `${apiKey}`);
  return fetchUtils.fetchJson(url, options);
}

const addSearchParam = (query, params) => {
  let { q } = params.filter;
  q = typeof q !== "undefined" ? q.replace(/^0/, '') : ''
  q = q.replace(/[ +-]/g, '');
  return {
    ...query,
    q: q
  }
}

const getQuery = (resource, action, params, query = {}) => {
  let include = [];
  if (resource === "lines" && action === "getList") {
    return addSearchParam({
      ...query,
      include: ["sims", "dids", "plans", "plans.plan", "suspensions", "suspensions.suspension"]
    }, params)
  }

  if ((resource === "sims" || resource === "dids" || resource === "ip_addresses" || resource === "bulk_requests" || resource === "events" || resource === "did_registrations") && action === "getList") {
    return addSearchParam(query, params)
  }

  if (resource === "lines" && action === "getOne") {
    include = ["sims", "sim_attributes", "sims.ip_addresses", "dids", "dids.voicemails", "dids.sms_forwarder_settings", "dids.tenant_smpp_routes", "dids.tenant_voice_routes", "dids.ringback_tones", "clids", "clids.destination_group", "plans.plan", "balances", "suspensions", "suspensions.suspension", "imeis", "line_home_roaming_operators", "sip_trunks", "sip_trunks.sip_domain", "sip_trunks.dial_plan", "sip_trunks.registrar_lcr", "line_tragofone_accounts"]
    if (params.include) {
      include = include.concat(params.include);
    }
    return {
      ...query,
      include
    }
  }

  if (resource === "plans" && action === "getList") {
    return {
      ...query,
      "filter[is_main]": params.filter.is_main,
      "filter[tenant_id]": params.filter.tenant_id
    }
  }

  if (resource === "line_requests" && action === "getList") {
    return {
      ...query,
      include: ["dids"]
    }
  }

  if (resource === "line_requests" && action === "getOne") {
    return {
      ...query,
      include: ["dids", "dids.port_in_request", "dids.port_in_request.internal_port_in_request", "topups", "status_transitions"]
    }
  }

  if (resource === "plans" && action === "getMany") {
    return {
      "filter[name]": JSON.stringify(params.ids),
    };
  }

  if (action === "getMany") {
    return {
      "filter[id][]": params.ids,
    };
  }

  if (resource === "show_me_white_paw_authentications" && action === "getList") {
    return {
      ...query,
      include: ["authentication_method"]
    }
  }

  return query;
}

const getFilter = (resource, action, params, query = {}) => {
  let filter = get(params, "filter", {});
  let number;

  if (resource === "bulk_requests" && action === "getList") {
    number = get(filter, "number", null)
    if (number !== null) {
      number = number.replace(/[ +-]/g, '').replace(/^0/, '972')
      filter["did.number"] = `+${number}`;
    }
    if (get(filter, "icc_id", null) !== null) {
      filter["sim.icc_id"] = get(filter, "icc_id", null);
    }
    return filter;
  }

  if (resource === "lines" && action === "getList") {
    number = get(filter, "number", null)
    if (number !== null) {
      number = number.replace(/[ +-]/g, '').replace(/^0/, '972')
      filter["did.number.end"] = `${number}`;
    }
    if (get(filter, "icc_id", null) !== null) {
      filter["sim.icc_id.end"] = get(filter, "icc_id", null);
    }
    return filter;
  }

  return filter;
}

const getUrl = (resource, action, params) => {
  if (resource === 'sims' && (action === 'getList' || action === 'getOne')) {
    return `${businessManagerApiUrl}/operational/sim_manager/${resource}`;
  }

  if (resource === 'ip_addresses' && (action === 'getList' || action === 'getOne')) {
    return `${businessManagerApiUrl}/operational/ip_provider/${resource}`;
  }

  if (resource === 'bulk_requests' || resource === "number_authentications") {
    return `${businessManagerApiUrl}/${resource}`;
  }

  if (resource === 'plans' || resource === 'topups' || resource === 'lines') {
    return `${businessManagerApiUrl}/operational/network_manager/${resource}`;
  }

  if (resource === 'show_me_white_paw_authentications') {
    return `${businessManagerApiUrl}/operational/show_me_white_paw/authentications`;
  }

  if (resource === 'business_manager_webhook_conversations' && action === 'getManyReference') {
    return `${businessManagerApiUrl}/webhook_endpoints/${params.id}/webhook_conversations`;
  }

  if (resource === "voicemails" && (action === "getOne" || action === "delete")) {
    const { line_id: lineId, did_id: didId } = parse(window.location.href);
    return `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/voicemails`;
  }

  if (resource === "voicemails" && (action === "update")) {
    const { line_id: lineId, did_id: didId } = params.data;
    return `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/voicemails`;
  }

  if (resource === "sms_forwarder_settings" && (action === "getOne" || action === "delete")) {
    const { line_id: lineId, did_id: didId } = parse(window.location.href);
    return `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/sms_forwarder_settings`;
  }

  if (resource === "sms_forwarder_settings" && (action === "update")) {
    const { line_id: lineId, did_id: didId } = params.data;
    return `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/sms_forwarder_settings`;
  }

  if (resource === 'tenant_smpp_routes') {
    return `${businessManagerApiUrl}/operational/network_manager/tenant_smpp_routes`;
  }

  if (resource === 'tenant_voice_routes') {
    return `${businessManagerApiUrl}/operational/network_manager/tenant_voice_routes`;
  }

  if (resource === "line_did_tenant_smpp_routes" && (action === "getOne" || action === "delete")) {
    const { line_id: lineId, did_id: didId } = parse(window.location.href);
    return `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/line_did_tenant_smpp_routes`;
  }

  if (resource === "line_did_tenant_voice_routes" && (action === "getOne" || action === "delete")) {
    const { line_id: lineId, did_id: didId } = parse(window.location.href);
    return `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/line_did_tenant_voice_routes`;
  }

  if (resource === 'did_registrations' && (action === 'getList' || action === 'getOne')) {
    return `${businessManagerApiUrl}/operational/did_provider/${resource}`;
  }

  if (resource === 'sim_manager_opls' && (action === "getList" || action === "getMany")) {
    return `${businessManagerApiUrl}/operational/sim_manager/opls`;
  }

  if (resource === "sip_trunk_passwords" && (action === "update")) {
    const { line_id: lineId, sip_trunk_id: sipTrunkId } = params.data;
    return `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/sip_trunks/${sipTrunkId}/password`;
  }

  return `${businessManagerApiUrl}/${resource}`
}


const getManyDecorator = (resource, params, data) => {
  if ((resource === "plans" || resource === "topups") && typeof params.ids !== "undefined") {
    //nasty hack to not get error "Associated reference no longer appears to be available."
    let planName = params.ids[0];
    if (planName.match(/^PLAN_/)) {
      return {
        data: [{
          id: planName,
          name: planName
        }]
      }
    }
  }
  return data;
}

const getListDecorator = (resource, json) => {
  let data = json.data;

  if (resource === "sims") {
    data = json.data.map(sim => ({
      id: sim.icc_id,
      ...sim
    }))
  }

  if (resource === "dids") {
    data = json.data.map(did => ({
      id: did.number,
      ...did
    }))
  }

  if (resource === "ip_addresses") {
    data = json.data.map(ip_address => ({
      id: ip_address.ip,
      ...ip_address
    }))
  }

  if (resource === "did_registrations") {
    data = json.data.map(did_registration => ({
      ...did_registration,
      id: `${did_registration.number}_${did_registration.created_at}_${did_registration.sequence}`,
    }))
  }


  return {
    data: data,
    total: parseInt(json.meta.total, 10)
  }
}

const getOneDecorator = (resource, data) => {
  if (resource === "lines") {
    let dids = data.dids.map((did) => {
      let clids = filter(data.clids, (clid => clid.caller_id === did.number && clid.end_at === null && clid.destination_group !== null));
      return {
        ...did,
        clids: clids,
        email: data.email
      }
    })

    dids = reverse(sortBy(dids, ['end_at', 'number']));

    let sims = reverse(sortBy(data.sims, ['end_at', 'icc_id']))
    let plans = reverse(sortBy(data.plans, ['end_at', 'plan.is_main', 'plan.name']))
    let suspensions = reverse(sortBy(data.suspensions, ['end_at']))
    let line_tragofone_accounts = reverse(sortBy(data.line_tragofone_accounts, ['end_at']))
    let sip_trunks = reverse(sortBy(data.sip_trunks, ['end_at']))

    return {
      data: { ...data, dids, sims, plans, line_tragofone_accounts, sip_trunks, suspensions }
    }
  }
  if (resource === "sims") {
    data = {
      ...data,
      id: data.icc_id
    }
  }
  if (resource === "dids") {
    data = {
      ...data,
      id: data.number
    }
  }
  return {
    data: data
  }
}

const updateDecorator = (resource, data) => {
  if (resource === "dids") {
    data = {
      ...data,
      id: data.number
    }
  }
  return {
    data: data
  }
}

const createPlans = ({ line_id: lineId, is_main: isMain, plan_id: planId, name }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/plans`;
  if (typeof planId !== "undefined" && planId !== "") {
    url = `${url}/${planId}/replace`
  }
  let payload = {
    plan_name: name
  }
  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createSims = ({ line_id: lineId, is_main: isMain, sim_id: simId, icc_id: iccId, ip }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/sims`;
  if (typeof simId !== "undefined" && simId !== "") {
    url = `${url}/${simId}/swap`
  }
  let payload = {
    icc_id: iccId,
    is_main: isMain
  }
  if (typeof ip !== 'undefined' && ip !== "") {
    payload.ip_addresses = [{ ip: ip, is_private: false, use_area: 'local' }];
  }
  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createSuspensions = ({ line_id: lineId, type }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/suspensions`;

  let payload = {
    type
  }
  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createDids = ({ line_id: lineId, number }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids`;

  let payload = {
    number
  }
  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createClids = ({ line_id: lineId, ...payload }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/clids`;

  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createVoicemails = ({ line_id: lineId, did_id: didId, ...payload }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/voicemails`;

  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createSmsForwarderSettings = ({ line_id: lineId, did_id: didId, ...payload }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/sms_forwarder_settings`;

  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createLineDidTenantSmppRoutes = ({ line_id: lineId, did_id: didId, ...payload }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/tenant_smpp_routes`;

  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createLineDidTenantVoiceRoutes = ({ line_id: lineId, did_id: didId, ...payload }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/tenant_voice_routes`;

  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createLineDidRingbackTones = ({ line_id: lineId, did_id: didId, file: file }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/dids/${didId}/ringback_tones`;

  var formdata = new FormData();
  formdata.append("file", file.rawFile, file.title);

  return httpClient(url, {
    method: 'POST',
    body: formdata,
  })
    .then(({ json }) => ({ data: json }))
}

const createOtaRequests = ({ line_id: lineId, sim_id: simId, ...payload }) => {
  let url = `${businessManagerApiUrl}/operational/lines/${lineId}/sims/${simId}/ota_requests`;
  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createLineHomeRoamingOperator = ({ line_id: lineId, line_home_roaming_operator_id: lineHomeRoamingOperatorId, ...payload }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/line_home_roaming_operators/${lineHomeRoamingOperatorId}/swap`;
  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const createIsraeliPortabilityTools = (resource, { number, ...payload }) => {
  let url = `${businessManagerApiUrl}/operational/israeli_portability/numbers/${number}/${resource}`;

  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: { id: 1, ...json } }))
}

const createLineTragofoneAccounts = ({ line_id: lineId, ...payload }) => {
  let url = `${businessManagerApiUrl}/operational/network_manager/lines/${lineId}/line_tragofone_accounts`;
  return httpClient(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(({ json }) => ({ data: json }))
}

const AlfredDataProvider = {
  getList: (resource, params) => {
    Debug.log({ resource, params, action: 'getList' });

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    let sort = order === "DESC" ? `-${field}` : field

    const query = getQuery(resource, "getList", params, {
      "page[number]": page,
      "page[size]": perPage,
      "sort": sort
    });

    const filter = getFilter(resource, "getList", params);

    Object.keys(filter || {}).forEach((key) => {
      query[`filter[${key}]`] = filter[key];
    });

    const url = `${getUrl(resource, 'getList')}?${stringify(query, { arrayFormat: 'bracket' })}`;

    return httpClient(url).then(({ json }) => (getListDecorator(resource, json)));
  },

  getOne: (resource, params) => {
    Debug.log({ resource, params, action: 'getOne' });

    const query = getQuery(resource, "getOne", params);

    const url = getUrl(resource, 'getOne');

    return httpClient(`${url}/${params.id}?${stringify(query, { arrayFormat: 'bracket' })}`)
      .then(({ json }) => {
        let data = (json.data ? json.data : json);
        return getOneDecorator(resource, data);
      })
  },

  getByUrl: (url) => {
    Debug.log({ url, action: 'getByUrl' });

    return httpClient(`${businessManagerApiUrl}/${url}`)
      .then(({ json }) => {
        let data = (json.data ? json.data : json);
        return data;
      })
  },

  getMany: (resource, params) => {
    Debug.log({ resource, params, action: 'getMany' });
    if (resource === "tenants" && params.ids && params.ids.length === 1) {
      return AlfredDataProvider.getOne(resource, { id: params.ids[0] }).then(json => {
        return {
          data: [json.data],
          total: 1
        }
      })
    }

    const query = getQuery(resource, "getMany", params);
    const url = `${getUrl(resource, 'getMany')}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => (getManyDecorator(resource, params, json)));
  },

  getManyReference: (resource, params) => {
    Debug.log({ resource, params, action: 'getManyReference' });

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    let sort = order === "DESC" ? `-${field}` : field

    const query = getQuery(resource, "getList", params, {
      "page[number]": page,
      "page[size]": perPage,
      "sort": sort
    });

    Object.keys(params.filter || {}).forEach((key) => {
      query[`filter[${key}]`] = params.filter[key];
    });

    query[`filter[${params.target}]`] = params.id;

    const url = `${getUrl(resource, 'getManyReference', params)}?${stringify(query, { arrayFormat: 'bracket' })}`;

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data,
        total: parseInt(json.meta.total, 10),
      }
    });

  },

  update: (resource, params) => {
    Debug.log({ resource, params, action: 'update' });

    const baseUrl = getUrl(resource, "update", params);

    const url = typeof params.id !== "undefined" ? `${baseUrl}/${params.id}` : baseUrl;

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => updateDecorator(resource, json))
  },

  updateMany: (resource, params) => {
    Debug.log({ resource, params, action: 'updateMany' });

    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${businessManagerApiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    Debug.log({ resource, params, action: 'create' });
    if (resource === "plans") {
      return createPlans(params.data);
    }
    if (resource === "sims") {
      return createSims(params.data);
    }
    if (resource === "suspensions") {
      return createSuspensions(params.data);
    }
    if (resource === "dids") {
      return createDids(params.data);
    }
    if (resource === "clids") {
      return createClids(params.data);
    }
    if (resource === "voicemails") {
      return createVoicemails(params.data);
    }
    if (resource === "ota_requests") {
      return createOtaRequests(params.data);
    }

    if (resource === "line_home_roaming_operators") {
      return createLineHomeRoamingOperator(params.data);
    }

    if (resource === "check_availability" || resource === "find_operator") {
      return createIsraeliPortabilityTools(resource, params.data);
    }

    if (resource === "line_tragofone_accounts") {
      return createLineTragofoneAccounts(params.data)
    }

    if (resource === "sms_forwarder_settings") {
      return createSmsForwarderSettings(params.data);
    }

    if (resource === "line_did_tenant_smpp_routes") {
      return createLineDidTenantSmppRoutes(params.data);
    }

    if (resource === "line_did_tenant_voice_routes") {
      return createLineDidTenantVoiceRoutes(params.data);
    }

    if (resource === "line_did_ringback_tones") {
      return createLineDidRingbackTones(params.data);
    }

    const url = getUrl(resource, 'create');
    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({ data: json }))
  },

  delete: (resource, params) => {
    const url = getUrl(resource, "delete", params);

    return httpClient(`${url}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${businessManagerApiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

export default AlfredDataProvider;
