import React from 'react';
import { Link } from 'react-router-dom';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const AddSuspensionButton = ({ classes, record }) => (
  <span>{record && (
    <Button

      className={classes.button}
      component={Link}
      to={`/suspensions/create?line_id=${record.id}`}
      label="Add suspension"
      title="Add a suspension"
    >
      <AcUnitIcon />
    </Button>
  )}</span>
);

export default withStyles(styles)(AddSuspensionButton);
