import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  useDataProvider
} from 'react-admin';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const HlrInfoButton = ({ classes, record }) => {
  const [open, setOpen] = React.useState(false);
  const [hlrInfos, setHlrInfos] = React.useState([]);
  const dataProvider = useDataProvider();

  const handleClickOpen = () => {
    return dataProvider
      .getOne("lines", { id: record.id, include: ["hlr_sims_current_info"] })
      .then(({ data }) => {
        let info = data.hlr_sims_current_info || [];
        setHlrInfos(info);
        setOpen(true);
      })
      .catch(error => {
        setHlrInfos(error);
        setOpen(true);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        className={classes.button}

        label="HLR Info"
        title="Get line info from the HLR"
        onClick={handleClickOpen}
      >
        <PermScanWifiIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">HLR Infos</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {hlrInfos.map((hlrInfo, k) => {
              return (
                <Table key={k}>
                  <TableBody>
                    {Object.keys(hlrInfo).map((key) => (
                      <div style={{ marginBottom: '0.25em' }}>
                        <div><strong>{key}</strong>:</div>
                        <div>{hlrInfo[key]}</div>
                      </div>
                    ))}
                  </TableBody>
                </Table>
              )
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus label="Close" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(HlrInfoButton);
