import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  required,
  email,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import { parse } from 'query-string';
import { Toolbar, SaveButton } from 'react-admin';
import { BackButton } from "../../lib/components";
import CloseButton from './CloseButton';

const VoicemailEditToolbar = props => {
  const { lineId, didId } = props;

  return (
    <Toolbar {...props}>
      <SaveButton />
      <BackButton />
      <CloseButton reference="voicemails" record={props.record} lineId={lineId} didId={didId} />
    </Toolbar>
  )
};


const VoicemailEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { line_id: lineId, did_id: didId, number } = parse(props.location.search);

  const transform = data => {
    return {
      ...data,
      line_id: lineId,
      did_id: didId,
    }
  }

  const onFailure = (error) => {
    notify(`Could not update voicemail: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`voicemail updated`);
    redirect(`/lines/${lineId}/show/1`);
    refresh();
  };

  return (
    <Edit undoable={false} onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Manage voicemail on ${number}`} {...props}>
      <SimpleForm redirect="show" toolbar={<VoicemailEditToolbar lineId={lineId} didId={didId} />}>
        <TextInput label="Email Address" source="email" type="email" validate={[required(), email()]} />
        <TextInput label="Full Name" source="fullname" validate={required()} />
        <TextInput label="Password" source="password" validate={required()} />
        <SelectInput label="Email Language" source="language" choices={[
          { id: 'fr', name: 'French' },
          { id: 'en', name: 'English' },
          { id: 'he', name: 'Hebrew' },
        ]} validate={required()} />
        <SelectInput label="Greeting Language" source="greeting_language" choices={[
          { id: 'fr', name: 'French' },
          { id: 'en', name: 'English' },
          { id: 'he', name: 'Hebrew' },
        ]} validate={required()} />
        <BooleanInput source="are_recordings_saved_on_server" label="Leave the messages on the server" fullWidth />
        <BooleanInput source="are_recordings_sent_to_email" label="Send the messages by email" fullWidth />
      </SimpleForm>
    </Edit>
  )
};

export default VoicemailEdit;