import React from 'react';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import {
  useNotify,
  useDataProvider,
  useRefresh,
  Button
} from 'react-admin';
import { get } from 'lodash';

const styles = {
  button: {
    marginLeft: '0.5em'
  }
};

const SendAuthNumberButton = ({ classes, record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const sendSms = () => {
    dataProvider
      .create('number_authentications', { data: { number: record.number } })
      .then(response => {
        notify("SMS Sent!")
        refresh()
      })
      .catch(error => {
        let errors = get(error.body, "errors");
        if (errors) {
          notify(`Could not resend sms: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        } else {
          notify("SMS Sent!")
        }
        refresh()
      })
  }

  return <Button
    onClick={sendSms}

    className={classes.button}
    label="New SMS Code"
    title="New SMS Code"
  >
    <SendIcon />
  </Button>;
}


export default withStyles(styles)(SendAuthNumberButton);