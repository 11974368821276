import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Button, FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    width: '100%'
  }
};

const TypeFilter = () => {
  const type = [
    "create",
    "remove",
    "add"
  ];

  return (
    <FilterList label="Type">
      {type.map((s) => (
        <FilterListItem
          label={s}
          value={{ type: s }}
          key={s}
        />
      ))}
    </FilterList>
  )
};


const StatusFilter = () => {
  const status = [
    "open",
    "done",
    "canceled",
    "failed",
    "ongoing",
    "ended"
  ];

  return (
    <FilterList label="Status">
      {status.map((s) => (
        <FilterListItem
          label={s}
          value={{ status: s }}
          key={s}
        />
      ))}
    </FilterList>
  )
};


const FilterSidebar = ({ setFilters, classes }) => (
  <Card style={{ marginLeft: '0.5em' }}>
    <CardContent>
      <Typography variant="h6">Filters:</Typography>
      <div><FilterLiveSearch label="Search" source="q" alwaysOn /></div>
      <div><FilterLiveSearch source="number" label="Number" /></div>
      <div><FilterLiveSearch source="icc_id" label="IccId" /></div>
      <div><FilterLiveSearch source="external_id" label="External Id" /></div>
      <StatusFilter />
      <TypeFilter />
      <Button className={classes.button} onClick={() => setFilters({})} label="Clear Filters" width="100%" />
    </CardContent>
  </Card>
);

export default withStyles(styles)(FilterSidebar);