import DidCreate from './DidCreate';
import DidList from './DidList';
import DialpadIcon from '@material-ui/icons/Dialpad';

const index = {
  create: DidCreate,
  list: DidList,
  icon: DialpadIcon

};

export default index;
