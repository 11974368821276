import * as React from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  required,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import { parse } from 'query-string';
import { CreateToolbar } from "../../lib/components";

const LineHomeRoamingOperatorCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { line_id: lineId, ref: ref, line_home_roaming_operator_id: lineHomeRoamingOperatorId, is_explicit: isExplicit } = parse(props.location.search);



  const transform = data => {
    return {
      ...data,
      line_id: lineId,
      line_home_roaming_operator_id: lineHomeRoamingOperatorId
    }
  }

  const onFailure = (error) => {
    notify(`Could not swap home roaming operator: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`home roaming operator swapped`);
    redirect(`/lines/${lineId}/show/7`);
    refresh();
  };

  let choices = [
    { id: '', name: 'no value - use default' },
    { id: 'PR', name: 'Partner' },
    { id: 'PL', name: 'Pelephone' }
  ];

  if (process.env.REACT_APP_DISPLAY_TENANT === "1") {
    choices = [
      { id: '', name: 'no value - use default' },
      { id: 'PR', name: 'Partner' },
      { id: 'PL', name: 'Pelephone' },
      { id: 'PR-PL', name: 'PR-PL' }
    ];
  }

  return (
    <Create onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Manage home roaming operator on ${lineId}`} {...props}>
      <SimpleForm
        toolbar={<CreateToolbar />}
        initialValues={{ ref: ref, is_explicit: !!parseInt(isExplicit) }}
      >
        <BooleanInput source="is_explicit" label="Home Roaming Explicit?" />
        <SelectInput label="Operator" source="ref" choices={choices} />
      </SimpleForm>
    </Create>
  )
};

export default LineHomeRoamingOperatorCreate;