import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Button, FilterList, FilterListItem, FilterLiveSearch, Filter } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    width: '100%'
  }
};

const TypeFilter = () => {
  const type = [
    "create",
    "remove",
    "add"
  ];

  return (
    <FilterList label="Type">
      {type.map((s) => (
        <FilterListItem
          label={s}
          value={{ type: s }}
          key={s}
        />
      ))}
    </FilterList>
  )
};


const OngoingFilter = () => {
  const ongoing = [
    "now"
  ];

  return (
    <FilterList label="Ongoing">
      {ongoing.map((s) => (
        <FilterListItem
          label={s}
          value={{ ongoing: s }}
          key={s}
        />
      ))}
    </FilterList>
  )
};


const FilterSidebar = ({ setFilters, classes, filterValues }) => {
  return (
    <Card style={{ marginLeft: '0.5em' }}>
      <CardContent>
        <Typography variant="h6">Filters:</Typography>
        <div><FilterLiveSearch label="Search" source="q" alwaysOn /></div>
        <div><FilterLiveSearch source="partial_id" label="Line Id" /></div>
        <div><FilterLiveSearch source="number" label="Number" /></div>
        <div><FilterLiveSearch source="icc_id" label="IccId" /></div>
        <div>
          <FilterList label="ongoing_at">
            <FilterListItem
              label="now"
              value={{ ongoing_at: "now" }}
              key="now"
            />
          </FilterList>
        </div>
        <Button className={classes.button} onClick={() => setFilters({})} label="Clear Filters" width="100%" />
      </CardContent>
    </Card>
  );
}

export default withStyles(styles)(FilterSidebar);