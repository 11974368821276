import React from 'react';
import { cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ExportButton,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  Filter,
  TextInput,
  Button,
  ReferenceField
} from 'react-admin';
import HasPelephoneOpl from './HasPelephoneOpl';

const SimFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const findAnEsim = async () => {
  let tenant = `${process.env.REACT_APP_TENANT_ID_UUID}`;
  let apiKey = `${process.env.REACT_APP_API_KEY}` || localStorage.getItem('api_key');

  let params = {
    method: 'post',
    headers: {
      "x-api-key": `${apiKey}`
    },
    body: ""
  }
  const res = await fetch(`${process.env.REACT_APP_DID_LIST_API_URL}?module=available_esim&tenantId=${tenant}`, params);
  res
    .json()
    .then(res => {
      if (res.icc_id !== '') {
        window.location = `/#/sims/${res.icc_id}/show`;
      }
    })
    .catch(err => {
      console.log(err)
    });
}


const SimListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    setFilters,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <Button onClick={() => setFilters({})} label="Clear Filters" />
      <Button onClick={() => findAnEsim()} label="Find an esim" />
      <ExportButton

        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        style={{ marginLeft: '0.5em' }}
      />
    </TopToolbar>
  );
};

const SimList = props => (
  <List
    {...props}
    filters={<SimFilter />}
    bulkActionButtons={false}
    actions={<SimListActions />}
    sort={{ field: 'icc_id', order: 'ASC' }}
  >
    <Datagrid rowClick="show">
      {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
        <ReferenceField label="Tenant" source="tenant_id" reference="tenants" link={false}>
          <TextField source="name" />
        </ReferenceField>
      )}
      <TextField source="icc_id" />
      <TextField source="type" />
      <HasPelephoneOpl />
    </Datagrid>
  </List>
);

export default SimList;