import React from 'react';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginLeft: '0.5em'
  }
};

const ShowLineButton = ({ classes, record }) => {
  return (
    <span>{record && (
      <Button

        className={classes.button}
        component={Link}
        to={`/lines/${record.line_id}/show`}
        label="View Line"
        title="View line"
      >
        <VisibilityIcon />
      </Button>
    )}</span>
  )
};

export default withStyles(styles)(ShowLineButton);
