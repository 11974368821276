import React from 'react';
import LoopIcon from '@material-ui/icons/Loop';
import {
  Button,
  useNotify,
  useDataProvider
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const ReuseProfileButton = ({ record, classes }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  if (typeof record === "undefined") {
    return (<span></span>);
  }
  const { icc_id, type } = record;

  if (typeof type === 'undefined' || type !== "esim") {
    return (<span></span>)
  }

  const reuseProfile = () => {
    return dataProvider
      .create(`operational/sim_manager/sims/${icc_id}/reuse_profile`, { data: { reuse_activation_code_token: false } })
      .then(response => {
        notify(`Reuse Done!`, "info");
      })
      .catch(error => {
        notify(`Could not reuse: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
      });
  }

  return (<Button className={classes.button} label="Reuse" onClick={reuseProfile}><LoopIcon /></Button>);
};

export default withStyles(styles)(ReuseProfileButton);

