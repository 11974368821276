import * as React from 'react';
import {
  EditButton,
} from 'react-admin'
import { withStyles } from '@material-ui/core/styles';
import ReplacePlanButton from './ReplacePlanButton';
import AddPlanButton from './AddPlanButton';
import ReplaceSimButton from './ReplaceSimButton';
import AddSimButton from './AddSimButton';
import AddSuspensionButton from './AddSuspensionButton';
import AddDidButton from './AddDidButton';
import HlrInfoButton from './HlrInfoButton';
import RefreshLineButton from './RefreshLineButton';
import CloseLineButton from './CloseLineButton';
import HardResetLineButton from './HardResetLineButton';
import PortinDidButton from './PortinDidButton';
import SwapHomeRoamingOperatorButton from './SwapHomeRoamingOperatorButton';
import AddTragofoneButton from './AddTragofoneButton';

const styles = {
  menu: {
    margin: '0em',
    padding: '0 1em 0 1em',
    borderLeft: '1px solid #ddd',
    background: '#fafafa',
    '& li': {
      'list-style-type': 'none'
    }
  }
};

const Aside = ({ classes, basePath, record }) => (
  <ul className={classes.menu}>
    <li><EditButton basePath={basePath} record={record} /></li>
    {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
      <li><SwapHomeRoamingOperatorButton record={record} /></li>
    )}
    <li><AddDidButton record={record} /></li>
    <li><PortinDidButton record={record} /></li>
    <li><ReplacePlanButton record={record} /></li>
    <li><AddPlanButton record={record} /></li>
    <li><ReplaceSimButton record={record} /></li>
    <li><AddSimButton record={record} /></li>
    <li><AddSuspensionButton record={record} /></li>
    <li><HlrInfoButton record={record} /></li>
    <li><RefreshLineButton record={record} /></li>
    <li><HardResetLineButton record={record} /></li>
    {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
      <li><AddTragofoneButton record={record} /></li>
    )}
    <li><CloseLineButton record={record} /></li>
  </ul>
);


export default withStyles(styles)(Aside);