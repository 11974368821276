import React from 'react';
import { cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ExportButton,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  Filter,
  TextInput,
  Button,
  DateField,
  CreateButton,
  ReferenceField
} from 'react-admin';
import { JSONShow } from '../../lib/components';
import AuthentifyNumberButton from './AuthentifyNumberButton';

const ShowMeWhitePawAuthenticationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Number" source="number" alwaysOn />
  </Filter>
);


const ShowMeWhitePawAuthenticationListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    setFilters,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
    basePath
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton
        basePath={basePath}
      />
      <Button onClick={() => setFilters({})} label="Clear Filters" />
      <ExportButton

        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        style={{ marginLeft: '0.5em' }}
      />
    </TopToolbar>
  );
};

const ShowMeWhitePawAuthenticationList = props => (
  <List
    {...props}
    filters={<ShowMeWhitePawAuthenticationFilter />}
    bulkActionButtons={false}
    actions={<ShowMeWhitePawAuthenticationListActions />}
    sort={{ field: 'inserted_at', order: 'DESC' }}
  >
    <Datagrid expand={<JSONShow />} rowClick="expand">
      {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
        <ReferenceField label="Tenant" source="authentication_method.tenant_id" reference="tenants" link={false}>
          <TextField source="name" />
        </ReferenceField>
      )}
      <TextField source="authentication_method.authentication_type" label="Type" />
      <TextField source="number" />
      <TextField source="locale" />
      <TextField source="status" />
      <DateField source="last_activity_at" showTime />
      <DateField source="inserted_at" showTime />
      <AuthentifyNumberButton />
    </Datagrid>
  </List>
);

export default ShowMeWhitePawAuthenticationList;