import React from 'react';
import { Link } from 'react-router-dom';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
  useRedirect
} from 'react-admin';
import CloseButton from '../voicemails/CloseButton';
import find from 'lodash/find';


export default function RingbackToneMenu({ record, lineId }) {

  const { ringback_tones } = record;
  let RingbackToneSetting = find(ringback_tones, { end_at: null });

  if (record.end_at !== null) {
    return (<span />);
  }

  if (RingbackToneSetting) {
    return (
      <span>
        <CloseButton label="RBT" reference="ringback_tones" record={RingbackToneSetting} lineId={lineId} didId={record.id} />
      </span>
    );
  }

  return (
    <span>{record && (
      <Button
        component={Link}
        to={`/line_did_ringback_tones/create?line_id=${lineId}&did_id=${record.id}&number=${encodeURIComponent(record.number)}`}
        label="RBT"
        title="RBT"
      >
        <AudiotrackIcon />
      </Button>
    )}</span>
  )
}
