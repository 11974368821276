import React, { useState, useEffect } from 'react';
import { AutocompleteInput, TextInput } from 'react-admin';
import moment from 'moment';
import get from 'lodash/get';

const FreeDidListInput = ({ label, name, filter, tenantId, ...attrs }) => {
  const [hasError, setErrors] = useState(true);
  const [dids, setDids] = useState([]);

  let tenant = typeof tenantId !== 'undefined' ? tenantId : `${process.env.REACT_APP_TENANT_ID_UUID}`;

  useEffect(() => {
    async function fetchData() {
      if (dids.length === 0) {
        let apiKey = `${process.env.REACT_APP_API_KEY}` || localStorage.getItem('api_key');
        let params = {
          method: 'post',
          headers: {
            "x-api-key": `${apiKey}`
          },
          body: ""
        }
        const res = await fetch(`${process.env.REACT_APP_DID_LIST_API_URL}?tenantId=${tenant}`, params);
        res
          .json()
          .then(res => {
            setErrors(false);
            setDids(filter(res));
          })
          .catch(err => {
            console.log(err)
            setErrors(err)
          });
      }
    }

    fetchData();
  }, []);

  if (typeof dids !== "undefined" && dids.length > 0) {
    const FreeDidListInputLabel = (record) => {
      if (typeof record === "undefined" || record === null) {
        return "";
      }

      const last_end_at = get(record, 'last_end_at', "n/c");
      if (last_end_at === null) {
        return `${record.number} - never used`;
      } else {
        return `${record.number} - last used ${moment(record.last_end_at).fromNow()}`;
      }
    }
    return (
      <AutocompleteInput choices={dids} optionText={FreeDidListInputLabel} optionValue="number" {...attrs} suggestionLimit={25} />
    )
  } else {
    return (<TextInput {...attrs} />)
  }

}

export default FreeDidListInput;