import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
} from 'react-admin';

const CloseButton = ({ reference, record, lineId, ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  if (record.end_at !== null) {
    return <span />
  }
  const close = () => {
    if (window.confirm('are you sure ?')) {
      return dataProvider
        .delete(`operational/network_manager/lines/${lineId}/${reference}`, { id: record.id })
        .then(response => {
          refresh();
          notify(`${reference} deleted`);
        })
        .catch(error => {
          notify(`Could not delete ${reference}: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        });
    }
    return false;
  }

  return <Button label="" onClick={close} {...props} style={{ color: 'red' }}><DeleteIcon /></Button>;
};

export default CloseButton;

