import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
  useRedirect
} from 'react-admin';
import find from 'lodash/find';


export default function VoicemailMenu({ record, lineId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateVoicemail = (voicemail, record) => {
    redirect(`/voicemails/${voicemail.id}?&line_id=${lineId}&did_id=${record.id}&number=${encodeURIComponent(record.number)}`); //HACK: adding & after ? to get the params in dataProvider
  }

  const removeVoicemail = (record, lineId, didId) => {
    if (window.confirm('are you sure that you want to remove the voicemail?')) {
      return dataProvider
        .delete(`operational/network_manager/lines/${lineId}/dids/${didId}/voicemails`, { id: record.id })
        .then(response => {
          redirect(`/lines/${lineId}/show/1`);
          refresh();
          notify(`voicemail deleted`);
        })
        .catch(error => {
          notify(`Could not delete voicemail: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        });
    }
    return false;
  }

  const removeGreetings = (lineId, didId) => {
    if (window.confirm('are you sure you want to remove all greetings?')) {
      return dataProvider
        .delete(`operational/lines/${lineId}/dids/${didId}`, { id: "voicemail_greeting" })
        .then(response => {
          redirect(`/lines/${lineId}/show/1`);
          refresh();
          notify(`Greetings deleted`);
        })
        .catch(error => {
          notify(`Could not delete greetings: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        });
    }
    return false;
  }

  const emptyMessages = (lineId, didId) => {
    if (window.confirm('are you sure you want to remove all messages in voicemail box?')) {
      return dataProvider
        .delete(`operational/lines/${lineId}/dids/${didId}`, { id: "voicemail_messages" })
        .then(response => {
          redirect(`/lines/${lineId}/show/1`);
          refresh();
          notify(`Messages deleted`);
        })
        .catch(error => {
          notify(`Could not delete messages: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        });
    }
    return false;
  }

  const { voicemails } = record;
  let voicemail = find(voicemails, { end_at: null });

  if (record.end_at !== null) {
    return (<span />);
  }

  if (voicemail) {
    return (
      <span>
        <Button label="Voicemail" onClick={handleClick}><VoicemailIcon /></Button>
        <Menu
          id="voicemail-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => { updateVoicemail(voicemail, record) }}><CreateIcon style={{ marginRight: '0.5em' }} />Update Voicemail</MenuItem>
          <MenuItem onClick={() => { removeGreetings(lineId, record.id) }}><VoicemailIcon style={{ marginRight: '0.5em' }} />Remove Greetings</MenuItem>
          <MenuItem onClick={() => { emptyMessages(lineId, record.id) }}><VoicemailIcon style={{ marginRight: '0.5em' }} />Empty Messages</MenuItem>
          <MenuItem onClick={() => { removeVoicemail(voicemail, lineId, record.id) }} style={{ color: 'red' }}><DeleteIcon style={{ marginRight: '0.5em' }} />Remove Voicemail</MenuItem>
        </Menu>
      </span>
    );
  }

  return (
    <span>{record && (
      <Button
        component={Link}
        to={`/voicemails/create?line_id=${lineId}&did_id=${record.id}&number=${encodeURIComponent(record.number)}&email=${encodeURIComponent(record.email)}`}
        label="Voicemail"
        title="Voicemail"
      >
        <VoicemailIcon />
      </Button>
    )}</span>
  )
}
