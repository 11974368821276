import AlfredDataProvider from './dataProvider';

const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    localStorage.setItem('api_key', password);
    return AlfredDataProvider.getOne("tenants", { id: "me" })
      .then(({ data }) => {
        if (data.name && data.name === username) {
          return data;
        }
        throw new Error('The username or password is incorrect');
      })
      .catch(() => {
        localStorage.removeItem('api_key');
        throw new Error('The username or password is incorrect');
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('api_key');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    // if (status === 401 || status === 403) {
    //   localStorage.removeItem('username');
    //   return Promise.reject();
    // }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    let apiKey = `${process.env.REACT_APP_API_KEY}` || localStorage.getItem('api_key');
    return apiKey
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};

export default authProvider;