import Chip from '@material-ui/core/Chip'
import get from 'lodash/get';

const TextArrayField = ({ record, source, path }) => {
  let array = get(record, source);
  if (typeof array === 'undefined' || array === null || array.length === 0) {
    return <div />
  } else {
    if (typeof path !== 'undefined') {
      return (
        <>
          {array.map(item => <Chip label={get(item, path)} key={get(item, path)} />)}
        </>
      )
    } else {
      return (
        <>
          {array.map(item => <Chip label={item} key={item} />)}
        </>
      )
    }
  }
}
TextArrayField.defaultProps = { addLabel: true }

export default TextArrayField