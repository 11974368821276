import ShowMeWhitePawAuthenticationCreate from './ShowMeWhitePawAuthenticationCreate';
import ShowMeWhitePawAuthenticationList from './ShowMeWhitePawAuthenticationList';
//import ShowMeWhitePawAuthenticationShow from './ShowMeWhitePawAuthenticationShow';
import ShowMeWhitePawAuthenticationCardIcon from '@material-ui/icons/PanTool';

const index = {
  create: ShowMeWhitePawAuthenticationCreate,
  list: ShowMeWhitePawAuthenticationList,
  icon: ShowMeWhitePawAuthenticationCardIcon,
  options: {
    label: 'Authentications'
  }
};

export default index;
