import WebhookEndpointList from './WebhookEndpointList';
import WebhookEndpointShow from './WebhookEndpointShow';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';

const index = {
  list: WebhookEndpointList,
  show: WebhookEndpointShow,
  icon: SettingsEthernetIcon
};

export default index;
