import * as React from "react";
import { Edit, SimpleForm, TextInput, SelectInput, BooleanInput, required, email } from 'react-admin';
import { CreateToolbar } from '../../lib/components';
import LineService from "../../services/LineService";

const LineEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="show" toolbar={<CreateToolbar />}>
      <TextInput source="identity_number" validate={required()} />
      <TextInput label="Email Address" source="email" type="email" validate={[required(), email()]} />
      <SelectInput source="language" choices={[
        { id: 'en_US', name: 'English' },
        { id: 'fr_FR', name: 'French' },
        { id: 'he_IL', name: 'Hebrew' },
      ]} validate={required()} />
      <SelectInput source="local_apn" choices={LineService.getApnList()} validate={required()} />
      <BooleanInput source="is_abroad_roaming_enabled" label="International Roaming Allowed?" />
      {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
        <BooleanInput source="is_volte_enabled" label="VoLTE Allowed?" />
      )}
    </SimpleForm>
  </Edit>
);

export default LineEdit;