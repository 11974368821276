import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
  useRedirect
} from 'react-admin';
import get from 'lodash/get';

const CloseButton = ({ reference, record, lineId, didId, ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const label = get(props, 'label', 'Close')

  if (record.end_at !== null) {
    return <span />
  }
  const close = () => {
    if (window.confirm('are you sure ?')) {
      return dataProvider
        .delete(`operational/network_manager/lines/${lineId}/dids/${didId}/${reference}`, { id: record.id })
        .then(response => {
          redirect(`/lines/${lineId}/show/1`);
          refresh();
          notify(`${reference} deleted`);
        })
        .catch(error => {
          notify(`Could not delete ${reference}: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        });
    }
    return false;
  }

  return <Button label={label} onClick={close} {...props} style={{ color: 'red' }}><DeleteIcon /></Button>;
};

export default CloseButton;

