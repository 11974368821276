import React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SmsIcon from '@material-ui/icons/Sms';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
  useRedirect
} from 'react-admin';
import CloseButton from '../voicemails/CloseButton';
import find from 'lodash/find';


export default function SmsForwarderMenu({ record, lineId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSmsForwarder = (sms_forwarder_settings, record) => {
    redirect(`/sms_forwarder_settings/${sms_forwarder_settings.id}?&line_id=${lineId}&did_id=${record.id}&number=${encodeURIComponent(record.number)}`); //HACK: adding & after ? to get the params in dataProvider
  }

  const removeSmsForwarder = (record, lineId, didId) => {
    if (window.confirm('are you sure that you want to remove the sms_forwarder_settings?')) {
      return dataProvider
        .delete(`operational/network_manager/lines/${lineId}/dids/${didId}/sms_forwarder_settings`, { id: record.id })
        .then(response => {
          redirect(`/lines/${lineId}/show/1`);
          refresh();
          notify(`sms_forwarder_settings deleted`);
        })
        .catch(error => {
          notify(`Could not delete sms_forwarder_settings: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        });
    }
    return false;
  }

  const { sms_forwarder_settings } = record;
  let smsForwarderSetting = find(sms_forwarder_settings, { end_at: null });

  if (record.end_at !== null) {
    return (<span />);
  }

  if (smsForwarderSetting) {
    return (
      <span>
        <CloseButton label="Sms Fwd" reference="sms_forwarder_settings" record={smsForwarderSetting} lineId={lineId} didId={record.id} />
      </span>
    );
  }

  return (
    <span>{record && (
      <Button
        component={Link}
        to={`/sms_forwarder_settings/create?line_id=${lineId}&did_id=${record.id}&number=${encodeURIComponent(record.number)}&email=${encodeURIComponent(record.email)}`}
        label="Sms Fwd"
        title="Sms Fwd"
      >
        <SmsIcon />
      </Button>
    )}</span>
  )
}
