import React from 'react';
import { cloneElement } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  CreateButton,
  ExportButton,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  ReferenceField
} from 'react-admin';
import BulkRequestSidebar from './BulkRequestListSidebar';
import ErrorIcon from '@material-ui/icons/Error';
import get from 'lodash/get';

const BulkRequestListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton
        basePath={basePath}

      />
      <ExportButton

        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        style={{ marginLeft: '0.5em' }}
      />
    </TopToolbar>
  );
};

const HasErrorField = ({ source, record = {} }) => {
  let error = get(record, source);
  if (error && error !== null && error !== '') {
    return (<ErrorIcon style={{ color: 'red' }} />);
  } else {
    return (<span></span>);
  }
}
HasErrorField.defaultProps = { label: 'Error' };


const BulkRequestList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<BulkRequestListActions />}
    aside={<BulkRequestSidebar />}
    sort={{ field: 'created_at', order: 'DESC' }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
        <ReferenceField label="Tenant" source="tenant_id" reference="tenants" link={false}>
          <TextField source="name" />
        </ReferenceField>
      )}
      <TextField source="external_id" />
      <TextField source="type" />
      <TextField source="line_id" />
      <ArrayField source="dids" linkType={false}><SingleFieldList><ChipField source="number" /></SingleFieldList></ArrayField>
      <TextField source="status" />
      <HasErrorField source="operational_port_in_request.error_message" />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);

export default BulkRequestList;