import React from 'react';
import { Link } from 'react-router-dom';
import DialpadIcon from '@material-ui/icons/Dialpad';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const AddDidButton = ({ classes, record }) => (
  <span>{record && (
    <Button

      className={classes.button}
      component={Link}
      to={`/dids/create?line_id=${record.id}`}
      label="Add did"
      title="Add a did"
    >
      <DialpadIcon />
    </Button>
  )}</span>
);

export default withStyles(styles)(AddDidButton);
