import React from 'react';
import { Link } from 'react-router-dom';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import find from 'lodash/find';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const SwapHomeRoamingOperatorButton = ({ classes, record }) => {
  let ref = "";
  let lineHomeRoamingOperatorId = ""
  let isExplicit = ""
  if (record) {
    let lineHomeRoamingOperator = find(record.line_home_roaming_operators, (lineHomeRoamingOperator => lineHomeRoamingOperator.end_at === null));
    if (lineHomeRoamingOperator) {
      lineHomeRoamingOperatorId = lineHomeRoamingOperator.id;
      isExplicit = lineHomeRoamingOperator.is_explicit ? "1" : "0";
      ref = lineHomeRoamingOperator.ref;
    }
  }
  return (
    <span>{record && (
      <Button

        className={classes.button}
        component={Link}
        to={`/line_home_roaming_operators/create?line_id=${record.id}&ref=${ref}&line_home_roaming_operator_id=${lineHomeRoamingOperatorId}&is_explicit=${isExplicit}`}
        label="Swap HR Op."
        title="Swap HR Op."
      >
        <PermDataSettingIcon />
      </Button>
    )}</span>
  );
}

export default withStyles(styles)(SwapHomeRoamingOperatorButton);
