import React from 'react';
import {
  TopToolbar,
  Button,
} from 'react-admin';
import { Link } from 'react-router-dom';

const Tools = props => (
  <TopToolbar style={{ justifyContent: "flex-start" }}>
    <Button
      component={Link}
      to={`/find-operator`}
      label="Find Operator"
      title="Find Operator"
    >
    </Button>

    <Button
      component={Link}
      to={`/check-availability`}
      label="Check Availability"
      title="Check Availability"
    >
    </Button>

  </TopToolbar>
);

export default Tools;