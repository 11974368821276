import SimCreate from './SimCreate';
import SimList from './SimList';
import SimShow from './SimShow';
import SimCardIcon from '@material-ui/icons/SimCard';

const index = {
  create: SimCreate,
  list: SimList,
  show: SimShow,
  icon: SimCardIcon
};

export default index;
