import * as React from "react";
import {
  Create,
  SimpleForm,
  FileInput,
  FileField,
  required,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin';
import { parse } from 'query-string';
import { CreateToolbar } from "../../lib/components";

const LineDidRingbackToneCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const { line_id: lineId, did_id: didId, number } = parse(props.location.search);

  const transform = data => {
    return {
      ...data,
      line_id: lineId,
      did_id: didId,
    }
  }

  const onFailure = (error) => {
    notify(`Could not create line_did_ringback_tone: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`line_did_ringback_tone created`);
    redirect(`/lines/${lineId}/show/1`);
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Manage Ringback Tone on ${number}`} {...props}>
      <SimpleForm
        toolbar={<CreateToolbar />}
      >
        <FileInput validate={required()} source="file" label="Ringback Tone File" multiple={false} maxSize={20000000}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
};

export default LineDidRingbackToneCreate;