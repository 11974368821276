import React from 'react';
import JSONTree from 'react-json-tree';

const JSONShow = ({ record, expandNode }) => {
  let shouldExpandNode = typeof expandNode !== "undefined" ? expandNode : true;
  return (
    <JSONTree data={record} theme="summerfruit" hideRoot={true} invertTheme={true} shouldExpandNode={(keyPath, data, level) => shouldExpandNode} />
  )
}

export default JSONShow;
