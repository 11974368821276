import React from 'react';
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import { withStyles } from '@material-ui/core/styles';
import {
  useNotify,
  useDataProvider,
  useRefresh,
  Button
} from 'react-admin';
import { get } from 'lodash';


const styles = {
  button: {
    marginLeft: '0.5em'
  }
};

const AuthentifyNumberButton = ({ classes, record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const authentify = () => {
    let code = prompt("Please enter the code received by sms");
    if (code !== null && code.trim() !== "") {
      dataProvider
        .update('show_me_white_paw_authentications', { id: record.number, data: { authentication_context: "port_in", authentication_type: "sms_code", code: code } })
        .then(response => {
          notify("OK! Number authentified!")
          refresh()
        })
        .catch(error => {
          let errors = get(error.body, "errors");
          if (errors) {
            notify(`Could not authenticate: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
          } else {
            notify("OK! Number authentified!")
          }
          refresh()
        })
    }
  }

  let authenticationType = get(record, 'authentication_method.authentication_type', null);
  let authenticationStatus = get(record, 'status', null);

  if ((authenticationType !== null && authenticationType !== 'sms_code') || (authenticationStatus !== null && authenticationStatus !== 'pending')) {
    return <React.Fragment />
  }

  return <Button
    onClick={authentify}

    className={classes.button}
    label="Authenticate Number"
    title="Authenticate Number"
  >
    <PhonelinkLockIcon />
  </Button>;
}

export default withStyles(styles)(AuthentifyNumberButton);