import * as React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  BooleanField,
  ArrayField,
  Datagrid,
  DateField
} from 'react-admin';
import QRCode from 'qrcode.react';
import { TextArrayField, BackButton } from '../../lib/components';
import { TopToolbar } from 'react-admin';
import ReuseProfileButton from '../lines/ReuseProfileButton';
import RecycleProfileButton from '../lines/RecycleProfileButton';
import GetEsimProfileButton from '../lines/GetEsimProfileButton';


const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <React.Fragment>
      {process.env.REACT_APP_DISPLAY_TENANT === "1" && (
        <React.Fragment>
          <ReuseProfileButton record={data} />
          <RecycleProfileButton record={data} />
        </React.Fragment>
      )}
      <GetEsimProfileButton record={data} />
    </React.Fragment>
  </TopToolbar>
);


const QRCodeField = ({ record }) => {
  if (record.type !== 'esim') {
    return (<span></span>)
  }

  return (<span><QRCode value={`LPA:${record.activation_code}`} /></span>)
}
QRCodeField.defaultProps = { addLabel: true }

const ActivationCodeField = ({ record }) => {
  if (record.activation_code !== '') {
    return (<span>{`LPA:${record.activation_code}`}</span>)
  } else {
    return (<span></span>)
  }
}
ActivationCodeField.defaultProps = { addLabel: true }

const SimShow = props => (
  <Show actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="type" />
        <TextField source="manufacturer.name" />
        <TextField source="icc_id" />
        <TextArrayField source="imsis" path="imsi" label="Imsis" />
        <TextField source="pin" />
        <TextField source="puk" />
        <TextField source="pin2" />
        <TextField source="puk2" />
        <TextField source="sm_dp_plus_address" />
        <TextField source="activation_code_token" />
        <ActivationCodeField source="activation_code" />
        <QRCodeField source="qr_code" />
        <BackButton style={{ marginLeft: 0, marginTop: '2em' }} />
      </Tab>

      <Tab label="technical">
        <ArrayField source="opls">
          <Datagrid>
            <TextField source="mcc_mnc" label="MCC MNC" />
            <TextField source="pnn" label="PNN" />
          </Datagrid>
        </ArrayField>
        <TextField source="spn" />
        <TextField source="ehplmn" />
        <TextField source="last_refresh_type" />
        <DateField source="last_refresh_at" showTime />
      </Tab>

    </TabbedShowLayout>
  </Show>
)

export default SimShow;
