import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import {
  TopToolbar,
  Show,
  TextField,
  ArrayField,
  SingleFieldList,
  ReferenceManyField,
  ReferenceField,
  Datagrid,
  DateField
} from 'react-admin';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import moment from 'moment';
import ShowLineButton from './ShowLineButton';
import AuthentifyNumberButton from '../show_me_white_paw_authentications/AuthentifyNumberButton';
import SendAuthNumberButton from './SendAuthNumberButton';
import CreateAuthenticationButton from './CreateAuthenticationButton';
import DuplicateBulkRequestButton from './DuplicateBulkRequestButton';
import CloseButton from './CloseButton';
import ExternalPortInRequestDetails from './ExternalPortInRequestDetails';
import find from 'lodash/find';
import get from 'lodash/get';
import { JSONShow } from '../../lib/components';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const BulkRequestShowActions = ({ data, resource }) => {
  let primaryDid = null;
  let operationalPortInRequest = null;
  let language = null;
  let localeForPortIn = null;
  if (data) {
    primaryDid = find(data.dids, (did) => did.number.match(/^\+972/));
    operationalPortInRequest = get(data, 'operational_port_in_request')
    language = get(data, 'language')
    localeForPortIn = get(data, 'operational_port_in_request.language')
  }

  return (
    <TopToolbar>
      {data
        && (data.status === "open" && data.port_in_request !== null) && (
          <div>
            {operationalPortInRequest && operationalPortInRequest.status === "open" && (
              <div>
                <AuthentifyNumberButton record={primaryDid} />
                <CreateAuthenticationButton record={primaryDid} locale={localeForPortIn} authenticationType="sms_code" successMessage="SMS Sent!" label="New SMS Code" />
                <CreateAuthenticationButton record={primaryDid} locale={localeForPortIn} authenticationType="ivr" successMessage="IVR Sent!" label="New IVR Call" />
              </div>
            )}
          </div>
        )}
      {data && (<CloseButton
        resource={resource}
        record={data}
        style={{ marginLeft: '0.5em' }}
      />)}
      {data && data.line_id !== null && (<ShowLineButton record={data} />)}
      {data && ['create', 'add'].includes(data.type) && ['failed', 'canceled'].includes(data.status) && (<DuplicateBulkRequestButton record={data} />)}
    </TopToolbar>
  )
};

const Aside = ({ record }) => (
  <Card style={{ marginLeft: '1em' }}>
    <CardContent>
      <Typography variant="h6">History</Typography>
      {record && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.status_transitions && record.status_transitions.map((status_transition) => (
              <TableRow key={`${status_transition.status}${status_transition.transitioned_at}`}>
                <TableCell component="th" scope="row">
                  {status_transition.status}
                </TableCell>
                <TableCell align="right">{moment(status_transition.transitioned_at).format("DD/MM/YYYY, HH:mm:ss")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </CardContent>
  </Card>
);

const OperationalPortInRequestInfo = ({ operationalPortInRequest, portInRequest }) => {
  if (operationalPortInRequest.status === "open") {
    return <span style={{ color: "#FF8C00", fontWeight: "bold" }}>waiting {portInRequest.authentication_type === 'ivr' ? 'call' : 'OTP'}</span>;
  }
  if (operationalPortInRequest.status === "sent") {
    return <span>port-in in progress</span>;
  }
  return <span></span>;
}

const PortInRequest = ({ record, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  if (record) {
    if (record.type === 'remove' || record.port_in_request === null) {
      return (<div></div>)
    }
    let operationalPortInRequest = get(record, "operational_port_in_request");
    let portInRequest = get(record, "port_in_request");
    return (

      <RaBox width="100%" style={{ padding: "0 0 20px 0" }} mt="20px">
        <Typography variant="h6" style={{ marginBottom: '1em' }}>Port In Request</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}></TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Number</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>TZ</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Operator</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Created At</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Transfer Time</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Error Message</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              operationalPortInRequest
              && (
                <Fragment>
                  <TableRow key={operationalPortInRequest.id} className={classes.root}>
                    <TableCell>
                      <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                      </IconButton>
                    </TableCell>
                    <TableCell>{operationalPortInRequest.number}</TableCell>
                    <TableCell>{operationalPortInRequest.identity_number}</TableCell>
                    <TableCell>{operationalPortInRequest.external_port_in_request && operationalPortInRequest.external_port_in_request.from_operator}</TableCell>
                    <TableCell>{moment(operationalPortInRequest.created_at).format("DD/MM/YYYY, HH:mm:ss")}</TableCell>
                    <TableCell>{operationalPortInRequest.external_port_in_request && operationalPortInRequest.external_port_in_request.transfer_time && moment(operationalPortInRequest.external_port_in_request.transfer_time).format("DD/MM/YYYY, HH:mm:ss")}</TableCell>
                    <TableCell>{operationalPortInRequest.status}</TableCell>
                    <TableCell>{operationalPortInRequest.external_port_in_request && operationalPortInRequest.error_message}</TableCell>
                    <TableCell><OperationalPortInRequestInfo operationalPortInRequest={operationalPortInRequest} portInRequest={portInRequest} /></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <ExternalPortInRequestDetails record={operationalPortInRequest.external_port_in_request} />
                          {operationalPortInRequest.external_port_in_request !== null && (
                            <React.Fragment>
                              <Typography variant="h6" gutterBottom component="div">
                                External Port In Request:
                              </Typography>
                              <JSONShow record={operationalPortInRequest.external_port_in_request} expandNode={false} />
                            </React.Fragment>
                          )}
                          <Typography variant="h6" gutterBottom component="div">
                            Authentication:
                          </Typography>
                          <Authentications operationalPortInRequest={operationalPortInRequest} style={{ marginBottom: '1em' }} {...props} />
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              )}
          </TableBody>
        </Table>
      </RaBox >
    );
  }
  return (<div></div>)
}

const Authentications = (props) => {
  let operationalPortInRequest = props.operationalPortInRequest;
  let filter = {
    number: operationalPortInRequest.number,
    authentication_context: "port_in"
  }
  return (
    <ReferenceManyField
      {...props}
      reference="show_me_white_paw_authentications"
      target="not_needed"
      filter={filter}
      sort={{ field: 'sequence', order: 'DESC' }}
    >
      <Datagrid expand={<JSONShow />} rowClick="expand">
        <TextField source="locale" />
        <TextField source="authentication_method.authentication_type" label="Type" />
        <DateField source="last_activity_at" showTime />
        <TextField source="status" />
      </Datagrid>
    </ReferenceManyField>
  )
}


const AuditEvents = (props) => {
  let record = props.record;
  let portInRequestId = record.dids && record.dids[0] && record.dids[0].port_in_request ? record.dids[0].port_in_request.id : undefined;
  let filter = { related_resource_id: record.id };
  if (portInRequestId) {
    filter = {
      resource_id: [
        record.id,
        portInRequestId
      ]
    }
  }
  return (
    <ReferenceManyField
      {...props}
      reference="events"
      target="not_needed"
      filter={filter}
      sort={{ field: 'sequence', order: 'ASC' }}
    >
      <Datagrid expand={<JSONShow />} rowClick="expand">
        <TextField source="sequence" />
        <TextField source="resource_id" />
        <TextField source="type" />
        <DateField source="created_at" showTime />
      </Datagrid>
    </ReferenceManyField>
  )
}

const BulkRequestShow = props => {
  return (
    <Show aside={<Aside />} actions={<BulkRequestShowActions />} {...props}>
      <BoxedShowLayout className="BoxedShowLayout">
        <RaBox>
          <RaBox width="100%" style={{ borderBottom: "1px solid #f0f0f0", padding: "0 0 20px 0" }}>
            <Typography variant="h6" style={{ marginBottom: '1em' }}>Status</Typography>
            <RaBox display="flex" flexWrap="wrap">
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="status" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="operational_port_in_request.error_message" label="Error" />
              </RaBox>
            </RaBox>
          </RaBox>
          <RaBox width="100%" style={{ borderBottom: "1px solid #f0f0f0", padding: "0 0 20px 0" }} mt="20px">
            <Typography variant="h6" style={{ marginBottom: '1em' }}>Infos</Typography>
            <RaBox display="flex" flexWrap="wrap">
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="type" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="line_id" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="sims[0].icc_id" label="Sim" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="plan.plan_name" label="Plan Name" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <ArrayField source="dids" linkType={false}><SingleFieldList><TextField source="number" style={{ marginRight: '0.5em' }} /></SingleFieldList></ArrayField>
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <ArrayField source="topups" linkType={false}><SingleFieldList><TextField source="topup_name" /></SingleFieldList></ArrayField>
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="email" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="identity_number" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="language" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField source="local_apn" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <TextField style={{ fontWeight: "bold" }} source="external_id" label="External ID" />
              </RaBox>
              <RaBox flex="0 0 50%" display="flex">
                <ReferenceField label="Tenant" source="tenant_id" reference="tenants" link={false}>
                  <TextField source="name" />
                </ReferenceField>
              </RaBox>
            </RaBox>
          </RaBox>

          <PortInRequest />
          <RaBox width="100%" style={{ padding: "0 0 20px 0" }} mt="20px">
            <Typography variant="h6" style={{ marginBottom: '1em' }}>Audit Events</Typography>
            <AuditEvents />
          </RaBox>
        </RaBox>
      </BoxedShowLayout >
    </Show >
  )
};

export default BulkRequestShow;
