import IpAddressList from './IpAddressList';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';

const index = {
  list: IpAddressList,
  icon: PermScanWifiIcon

};

export default index;
