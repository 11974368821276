import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  required,
  ReferenceInput
} from 'react-admin';
import { parse } from 'query-string';
import { FilteredSelectInput, CreateToolbar } from "../../lib/components";
import compareName from '../../lib/compareName';

const PlanCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { line_id: lineId, is_main: is_main_string, plan_id: planId } = parse(props.location.search);

  const isMain = is_main_string === "0" ? false : true;

  const transform = data => {
    return {
      line_id: lineId,
      is_main: isMain,
      name: data.name,
      plan_id: planId
    }
  }

  const onFailure = (error) => {
    notify(`Could not create plan: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`Plan created`);
    redirect(`/lines/${lineId}/show/3`);
  };

  const verb = isMain ? 'Replace' : 'Add';
  const reference = isMain ? 'plans' : 'topups';

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`${verb} plan on line #${lineId}`}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <ReferenceInput
          fullWidth
          source="name"
          reference={reference}
          validate={required()}
          filter={process.env.REACT_APP_TENANT_ID_SHORTCODE !== "" ? ({ is_main: isMain, tenant_id: `${process.env.REACT_APP_TENANT_ID_SHORTCODE}` }) : ({ is_main: isMain })}
          label="Plan Name"
          sort={false}
          perPage={1000}
        >
          <FilteredSelectInput optionText="name" optionValue="name" filter={(choices) => {
            return choices.sort(compareName)
          }} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default PlanCreate;