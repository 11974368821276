import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  TextInput
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { parse } from 'query-string';
import { FilteredSelectInput, CreateToolbar } from "../../lib/components";
import sortBy from 'lodash/sortBy'


let actions = ['set_selection_mode'];
const datas = {
  set_selection_mode: ['manual', 'automatic'],
  set_spn_name: [''],
  reload_account: [''],
};
if (process.env.REACT_APP_DISPLAY_TENANT === "1") {
  actions.push('set_plmn_name');
  actions.push('set_spn_name');
  actions.push('reload_account');

}

const toChoices = items => items.map(item => ({ id: item, name: item }));

const DataInput = props => {
  const { values } = useFormState();
  const action = values.action;
  const tenantId = props.tenantId;

  if (action && action === "set_spn_name") {
    return (
      <TextInput {...props} />
    )
  }

  if (action && action === "set_plmn_name") {
    return (
      <ReferenceInput
        fullWidth
        reference="sim_manager_opls"
        allowEmpty
        filter={{ tenant_id: tenantId }}
        sort={{}}
        perPage={10000}
        {...props}
      >
        <FilteredSelectInput optionText="name" optionValue="id" filter={(choices) => {
          return sortBy(choices, ['tenant_id', 'mcc', 'mnc']).filter(item => item.tenant_id === tenantId).map(choice => (
            {
              ...choice,
              name: `${choice.mcc}${choice.mnc} - ${choice.pnn}`
            }))
        }} />
      </ReferenceInput>
    );
  }

  return (
    <SelectInput
      choices={values.action ? toChoices(datas[values.action]) : []}
      {...props}
    />
  );
};

const OtaRequestCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { line_id: lineId, sim_id: simId, tenant_id: tenantId } = parse(props.location.search);

  const transform = data => {
    if (data.action === "set_plmn_name") {
      return {
        line_id: lineId,
        sim_id: simId,
        action: data.action,
        data: {
          opl_id: data.data
        }
      }
    }

    if (data.action === "set_spn_name") {
      return {
        line_id: lineId,
        sim_id: simId,
        action: data.action,
        data: {
          spn: (data.data || "")
        }
      }
    }

    return {
      line_id: lineId,
      sim_id: simId,
      ...data
    }
  }

  const onFailure = (error) => {
    notify(`Could not create OTA request: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`OTA request created, please wait 1-2 minutes for the answer...`);
    redirect(`/lines/${lineId}/show/2`);
  };

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`Send OTA on line #${lineId} - sim #${simId}`}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <SelectInput source="action" choices={toChoices(actions)} />
        <DataInput source="data" tenantId={tenantId} />
      </SimpleForm>
    </Create>
  )
}

export default OtaRequestCreate;