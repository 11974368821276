import React from 'react';
import { Link } from 'react-router-dom';
import SmsIcon from '@material-ui/icons/Sms';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
  useRedirect
} from 'react-admin';
import CloseButton from '../voicemails/CloseButton';
import find from 'lodash/find';


export default function TenantSmppRouteMenu({ record, lineId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateTenantSmppRoute = (line_did_tenant_smpp_routes, record) => {
    redirect(`/line_did_tenant_smpp_routes/${line_did_tenant_smpp_routes.id}?&line_id=${lineId}&did_id=${record.id}&number=${encodeURIComponent(record.number)}`); //HACK: adding & after ? to get the params in dataProvider
  }

  const removeTenantSmppRoute = (record, lineId, didId) => {
    if (window.confirm('are you sure that you want to remove the line_did_tenant_smpp_routes?')) {
      return dataProvider
        .delete(`operational/network_manager/lines/${lineId}/dids/${didId}/line_did_tenant_smpp_routes`, { id: record.id })
        .then(response => {
          redirect(`/lines/${lineId}/show/1`);
          refresh();
          notify(`line_did_tenant_smpp_routes deleted`);
        })
        .catch(error => {
          notify(`Could not delete line_did_tenant_smpp_routes: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        });
    }
    return false;
  }

  const { tenant_smpp_routes } = record;
  let TenantSmppRouteSetting = find(tenant_smpp_routes, { end_at: null });

  if (record.end_at !== null) {
    return (<span />);
  }

  if (TenantSmppRouteSetting) {
    return (
      <span>
        <CloseButton label="SmppRoute" reference="tenant_smpp_routes" record={TenantSmppRouteSetting} lineId={lineId} didId={record.id} />
      </span>
    );
  }

  return (
    <span>{record && (
      <Button
        component={Link}
        to={`/line_did_tenant_smpp_routes/create?line_id=${lineId}&did_id=${record.id}&number=${encodeURIComponent(record.number)}`}
        label="SmppRoute"
        title="SmppRoute"
      >
        <SmsIcon />
      </Button>
    )}</span>
  )
}
