import React from 'react';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import {
  Button,
  useNotify,
  useDataProvider
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const RefreshLineButton = ({ record, classes }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const refreshLine = () => {
    return dataProvider
      .create(`operational/network_manager/lines/${record.id}/refresh`, {})
      .then(response => {
        notify(`Refresh Done!`, "info");
      })
      .catch(error => {
        notify(`Refresh Done!`, "info");
      });
  }

  return <Button className={classes.button} label="Refresh Line" title="Do Cancel Location" onClick={refreshLine}><SignalCellularAltIcon /></Button>;
};

export default withStyles(styles)(RefreshLineButton);

