import * as React from 'react';
import {
  Show,
  TextField,
  ArrayField,
  SingleFieldList,
  ReferenceManyField,
  Datagrid,
  BooleanField,
  DateField,
  Pagination
} from 'react-admin';
import { JSONShow } from '../../lib/components';
import ImeiFied from './ImeiField';

const getInfo = (record) => {
  switch (record.type) {
    case 'sm_dp_plus.download_progress_info':
      return record.data.notification_point;
    case 'sim.hss.imei_changed':
      return <ImeiFied source="data.imei" record={record} />;
    case 'sim.imei_changed':
      return <ImeiFied source="data.imei" record={record} />;
    case 'sim.hss.plmn_changed':
      return `MCC: ${record.data.mcc} - MNC: ${record.data.mnc}`;
    case 'sim.roaming':
      return `MCC: ${record.data.mcc} - MNC: ${record.data.mnc_list}`;
    default:
      return '';
  }
}

const Infofield = ({ record }) => (<span key={record.id}>{getInfo(record)}</span>)
Infofield.defaultProps = { label: 'Info' };

const LineSimEvents = (props) => {
  let record = props.record;
  return (
    <ReferenceManyField
      {...props}
      reference={`events`}
      target="icc_id"
      filter={{ resource_object: "sim", resource_id: record.icc_id }}
      sort={{ field: 'sequence', order: 'DESC' }}
      perPage={1000}
      pagination={<Pagination />}
      expand={<JSONShow />} rowClick="expand"
    >
      <Datagrid>
        <TextField source="type" />
        <Infofield />
        <DateField source="created_at" showTime />
      </Datagrid>
    </ReferenceManyField>
  )
}

export default LineSimEvents;