import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Button,
  useNotify,
  useRefresh,
  useRedirect,
  useDataProvider
} from 'react-admin';
import CloseButton from './CloseButton';
import VoicemailMenu from './VoicemailMenu';
import SmsForwarderMenu from './SmsForwarderMenu';
import TenantSmppRouteMenu from './TenantSmppRouteMenu';
import TenantVoiceRouteMenu from './TenantVoiceRouteMenu';
import RingbackToneMenu from './RingbackToneMenu';


export default function DidMenu({ record, lineId }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addClid = () => {
    redirect(`/clids/create?line_id=${lineId}&caller_id=${encodeURIComponent(record.number)}`);
  }

  const close = (reference, record, lineId) => {
    if (record.end_at !== null) {
      refresh();
      handleClose();
      return false;
    }

    if (window.confirm('are you sure ?')) {
      return dataProvider
        .delete(`operational/network_manager/lines/${lineId}/${reference}`, { id: record.id })
        .then(response => {
          refresh();
          notify(`${reference} deleted`);
        })
        .catch(error => {
          notify(`Could not delete ${reference}: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
        });
    }
    return false;

  }

  const clids = record.clids;

  if (record.end_at !== null) {
    return (<span key={`didmenu${record.id}`} />);
  }

  return (
    <span key={`didmenu${record.id}`}>
      {clids && clids.length > 0 && (
        <span>
          <Button label="CLID" onClick={handleClick}><ContactPhoneIcon /></Button>
          <Menu
            id="did-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={addClid}><AddIcon style={{ marginRight: '0.5em' }} />CLID</MenuItem>
            {clids && clids.map(clid => (
              <MenuItem onClick={() => { close("clids", clid, lineId) }} style={{ color: 'red' }} key={`clid-${clid.destination_group.name}-${clid.service}`}><DeleteIcon style={{ marginRight: '0.5em' }} />Remove CLID {clid.destination_group.name} {clid.service}</MenuItem>
            ))}
          </Menu>
        </span>
      )}
      {clids && clids.length === 0 && (
        <span>{record && (
          <Button label="CLID" onClick={addClid}><ContactPhoneIcon /></Button>
        )}</span>
      )}
      <VoicemailMenu record={record} lineId={lineId} style={{ marginLeft: '0.5em' }} />
      <RingbackToneMenu record={record} lineId={lineId} style={{ marginLeft: '0.5em' }} />
      <SmsForwarderMenu record={record} lineId={lineId} style={{ marginLeft: '0.5em' }} />
      <TenantSmppRouteMenu record={record} lineId={lineId} style={{ marginLeft: '0.5em' }} />
      <TenantVoiceRouteMenu record={record} lineId={lineId} style={{ marginLeft: '0.5em' }} />
    </span>
  );
}
