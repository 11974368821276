import React from 'react';
import find from 'lodash/find';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const StatusField = ({ record }) => {
  let color = 'green';
  let suspension = find(record.suspensions, { 'end_at': null });
  if (typeof suspension !== 'undefined') {
    color = suspension.suspension.type === 'block' ? 'red' : 'yellow';
  }
  let plan = find(record.plans, (plan) => {
    return plan.plan.is_main && plan.end_at === null
  });
  if (typeof plan === 'undefined') {
    color = 'black';
  }
  return <span><FiberManualRecordIcon style={{ color: color }} /></span>
}

export default StatusField;