import * as React from "react";

const FreshChat = () => {

  if (process.env.REACT_APP_DISPLAY_FRESHCHAT === "1") {
    window.fcWidget.init({
      token: "6895d23e-8a94-48f8-96b9-57c0b921f74a",
      host: "https://annatel.freshchat.com",
      "config": {
        "agent": {
          "hideName": true
        }
      },
      widgetUuid: "e829171d-a8af-400d-b37e-b42adfb8e721",
      firstName: process.env.REACT_APP_DISPLAY_FRESHCHAT_NAME,
      externalId: process.env.REACT_APP_DISPLAY_FRESHCHAT_NAME.toLowerCase(),
      email: process.env.REACT_APP_DISPLAY_FRESHCHAT_EMAIL
    });
    //window.fcWidget.setExternalId(process.env.REACT_APP_DISPLAY_FRESHCHAT_NAME.toLowerCase());
    //window.fcWidget.setFirstName(process.env.REACT_APP_DISPLAY_FRESHCHAT_NAME);
    // window.fcWidget.setEmail(process.env.REACT_APP_DISPLAY_FRESHCHAT_EMAIL);
  }
  return <React.Fragment></React.Fragment>
}

export default FreshChat;