import React from 'react';
import LoopIcon from '@material-ui/icons/Loop';
import {
  Button,
  useNotify,
  useDataProvider
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const RecycleProfileButton = ({ record, classes }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  if (typeof record === "undefined") {
    return (<span></span>);
  }
  const { icc_id, type } = record;

  if (typeof type === 'undefined' || type !== "esim") {
    return (<span></span>)
  }

  const recycleProfile = () => {
    return dataProvider
      .create(`operational/sim_manager/sims/${icc_id}/recycle_profile`, { data: {} })
      .then(response => {
        notify(`Recycle Done!`, "info");
      })
      .catch(error => {
        notify(`Could not Recycle Profile: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
      });
  }

  return (<Button className={classes.button} label="Recycle" onClick={recycleProfile}><LoopIcon /></Button>);
};

export default withStyles(styles)(RecycleProfileButton);

