import React from 'react';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
} from 'react-admin';

const SipTrunkChangePasswordButton = ({ reference, record, lineId, ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  if (record.end_at !== null) {
    return <span />
  }
  const changePassword = () => {
    if (window.confirm('are you sure ?')) {
      return dataProvider
        .update(`sip_trunk_passwords`, { data: { line_id: lineId, sip_trunk_id: record.id } })
        .then(response => {
          refresh();
          notify(`password changed`);
        })
        .catch(error => {
          notify(`password changed`);
        });
    }
    return false;
  }

  return <Button label="Change Password" onClick={changePassword} {...props}><VpnKeyIcon /></Button>;
};

export default SipTrunkChangePasswordButton;

