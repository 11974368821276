import React from 'react';
import { Link } from 'react-router-dom';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginLeft: '0em'
  }
};

const PortinDidButton = ({ classes, record }) => (
  <span>{record && (
    <Button

      className={classes.button}
      component={Link}
      to={`/bulk_requests/create?type=add&line_id=${record.id}&language=${record.language}`}
      label="Port-in did"
      title="Port-in a did"
    >
      <AddIcCallIcon />
    </Button>
  )}</span>
);

export default withStyles(styles)(PortinDidButton);
