import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  required
} from 'react-admin';
import { parse } from 'query-string';
import { CreateToolbar, FreeIpListInput } from '../../lib/components';

const SimCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { line_id: lineId, is_main: is_main_string, sim_id: simId } = parse(props.location.search);

  const isMain = is_main_string === "0" ? false : true;

  const transform = data => {
    let sim = {
      line_id: lineId,
      is_main: isMain,
      icc_id: data.icc_id,
      sim_id: simId
    }
    if (data.has_custom_ip) {
      if (typeof data.ip != "undefined" && data.ip !== "") {
        sim.ip = data.ip;
      }
    }
    return sim;
  }

  const onFailure = (error) => {
    notify(`Could not create sim: ${JSON.stringify(error.body.errors, 0, 2)}`, "error", {}, false);
  };

  const onSuccess = ({ data }) => {
    notify(`Sim created`);
    redirect(`/lines/${lineId}/show/2`);
  };

  const verb = isMain ? 'Replace' : 'Add';

  return (
    <Create {...props} onSuccess={onSuccess} onFailure={onFailure} transform={transform} title={`${verb} SIM on line #${lineId}`}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput fullWidth source="icc_id" label="SIM Number" validate={required()} />
        {!simId && (<BooleanInput source="has_custom_ip" label="Do you want to setup a custom ip ?" fullWidth />)}
        {!simId && (
          <FormDataConsumer fullWidth>
            {({ formData, ...rest }) => formData.has_custom_ip &&
              <FreeIpListInput
                fullWidth
                source="ip"
                validate={required()}
              />
            }
          </FormDataConsumer>
        )}
      </SimpleForm>
    </Create>
  )
}

export default SimCreate;