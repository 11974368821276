import DidRegistrationList from './DidRegistrationList';
import ListIcon from '@material-ui/icons/List';

const index = {
  list: DidRegistrationList,
  icon: ListIcon,
  options: {
    label: 'Did registrations'
  }
};

export default index;
